// Home:全てのお知らせ
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Box, Typography, Divider, Button, Grid } from "@mui/material";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import { HomeNotificationList } from "../Components/Home/HomeNotificationList";
import { ContentCard } from "../Components/Share/ContentCard";
import { CustomPagination } from "../Components/Share/CustomPagination";
import { useQueryMeApi } from "../hooks/useQueryMeApi";
import { useQueryNotificationListApi } from "../hooks/useQueryNotificationListApi";
import { CreateNotificationDialog } from "../Components/NotificationDialog/CreateNotificationDialog";
import { onClickCreateNotificationDialogOpen } from "../features/notificationStateSlice";
import { PostNotificationCheckDialog } from "../Components/NotificationDialog/PostNotificationCheckDialog";
import { EditNotificationDialog } from "../Components/NotificationDialog/EditNotificationDialog";
import { PutNotificationCheckDialog } from "../Components/NotificationDialog/PutNotificationCheckDialog";
import { HomeNotificationDialog } from "../Components/Home/HomeNotificationDialog";
import { ScrollBox } from "../Components/Share/ScrollBox";

export const HomeNotification = () => {
  const dispatch = useDispatch();
  const { index } = useParams();
  const { data, isLoading } = useQueryNotificationListApi(index);
  const me = useQueryMeApi();

  return (
    <>
      <HomeNotificationDialog />
      <CreateNotificationDialog />
      <PostNotificationCheckDialog />
      <EditNotificationDialog />
      <PutNotificationCheckDialog />
      <ContentCard>
        <Grid container>
          <Grid item xs={12} sm={12} md={5} sx={{ display: "flex" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                color: "base.contrastText",
              }}
            >
              <AnnouncementIcon />
              <Typography variant="h7" sx={{ ml: 1 }}>
                お知らせ一覧
              </Typography>
              {me.data.is_staff && (
                <Button
                  onClick={() =>
                    dispatch(onClickCreateNotificationDialogOpen())
                  }
                  sx={{ maxHeight: 0, ml: 4 }}
                >
                  新規作成
                </Button>
              )}
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={7}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <CustomPagination
              count={isLoading || !data ? null : data.count}
              loading={isLoading}
            />
          </Grid>
        </Grid>
        <Divider sx={{ mt: 0.6 }} />
        <ScrollBox>
          <HomeNotificationList data={data} isLoading={isLoading} />
        </ScrollBox>
      </ContentCard>
    </>
  );
};

// 自分の記事管理_記事確認Dialog
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ArticleDialog } from "../ArticleDialog/ArticleDialog";
import { handleMyArticleDialog } from "../../features/articleStateSlice";
import { useQueryMyArticleApi } from "../../hooks/useQueryMyArticleApi";

export const MyPageMyArticleDialog = memo(() => {
  const dispatch = useDispatch();
  const { myArticleDialog } = useSelector((state) => state.articleState);
  const { data } = useQueryMyArticleApi(myArticleDialog.id);

  return (
    <>
      <ArticleDialog
        open={myArticleDialog.dialog}
        onClickClose={() => dispatch(handleMyArticleDialog())}
        data={data}
      />
    </>
  );
});

// 共通Component_コンテンツカード
import { Card } from "@mui/material";

export const ContentCard = ({ children, sx }) => {
  return (
    <Card
      sx={{
        p: 2,
        bgcolor: "rgba(255,255,255,0.8)",
        zIndex: 1,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        ...sx,
      }}
    >
      {children}
    </Card>
  );
};

// その他のグローバル管理State
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sideDrawer: false,
  ownerDialog: false,
  ownerData: {
    id: "",
    display_name: "",
    email: "",
  },
};

const otherStateSlice = createSlice({
  name: "mobileSideDrawer",
  initialState,
  reducers: {
    handleSideDrawer: (state) => {
      state.sideDrawer = !state.sideDrawer;
    },
    handleOwnerDialog: (state, actions) => {
      if (!state.ownerDialog) {
        state.ownerData = actions.payload;
      }
      state.ownerDialog = !state.ownerDialog;
    },
  },
});

export const { handleSideDrawer, handleOwnerDialog } = otherStateSlice.actions;

export default otherStateSlice.reducer;

// Header_検索エリア_タグ検索・検索設定Popper
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Grid,
  Card,
  Typography,
  Popper,
  Paper,
  ClickAwayListener,
  Checkbox,
  FormControlLabel,
  Divider,
  Button,
} from "@mui/material";
import { HeaderSearchAreaSettingRadioGroup } from "./HeaderSearchAreaSettingRadioGroup";
import {
  onChangeArticleSearchParams,
  onClickClearArticleSearchParams,
} from "../../../features/articleSearchParamsSlice";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import FilterListIcon from "@mui/icons-material/FilterList";
import SettingsIcon from "@mui/icons-material/Settings";
import { HeaderSearchAreaTagList } from "./HeaderSearchAreaTagList";

export const HeaderSearchAreaPopper = memo((props) => {
  const dispatch = useDispatch();
  const {
    articleSearchMenuPopper,
    articleSearchMenuAnchorRef,
    onClickAwayArticleSearchMenuPopperClose,
    onClickArticleSearch,
    tagItems,
    handleKeyDown,
  } = props;

  const { articleSearchParams } = useSelector(
    (state) => state.articleSearchParams
  );

  return (
    <Popper
      sx={{ zIndex: 1 }}
      open={articleSearchMenuPopper}
      anchorEl={articleSearchMenuAnchorRef.current}
      disablePortal
    >
      <Paper
        elevation={6}
        sx={{
          width: {
            xs: "100vw",
            sm: "58vw",
            md: "40vw",
            lg: "56vw",
          },
          p: 1,
          maxHeight: "78vh",
          overflow: "auto",
          margin: "auto",
          bgcolor: "rgba(249,250,250)",
          borderRadius: "0 0 20px 20px",
        }}
      >
        <ClickAwayListener
          onClickAway={onClickAwayArticleSearchMenuPopperClose}
        >
          <Box onKeyDown={handleKeyDown}>
            <Grid container spacing={1}>
              <Grid item xs={12} lg={7}>
                <Card sx={{ p: 2 }}>
                  <Box sx={{ display: "flex", color: "base.contrastText" }}>
                    <LocalOfferIcon fontSize="small" />
                    <Typography variant="body2" sx={{ ml: 1, mb: 1 }}>
                      Tag指定
                    </Typography>
                  </Box>
                  <HeaderSearchAreaTagList tagItems={tagItems} />
                </Card>
              </Grid>
              <Grid item xs={12} lg={5}>
                <Card sx={{ p: 2 }}>
                  <Box sx={{ display: "flex", color: "base.contrastText" }}>
                    <FilterListIcon fontSize="small" />
                    <Typography variant="body2" sx={{ ml: 1, mb: 1 }}>
                      検索範囲指定
                    </Typography>
                  </Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={articleSearchParams.myArticle}
                        onChange={(event) =>
                          dispatch(
                            onChangeArticleSearchParams({
                              myArticle: event.target.checked,
                            })
                          )
                        }
                      />
                    }
                    label={<Typography variant="body2">自分の記事</Typography>}
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={articleSearchParams.bookmarked}
                        onChange={(event) =>
                          dispatch(
                            onChangeArticleSearchParams({
                              bookmarked: event.target.checked,
                            })
                          )
                        }
                      />
                    }
                    label={
                      <Typography variant="body2">
                        ブックマークした記事
                      </Typography>
                    }
                  />
                </Card>
                <Card sx={{ mt: 1, p: 2 }}>
                  <Box sx={{ display: "flex", color: "base.contrastText" }}>
                    <SettingsIcon fontSize="small" />
                    <Typography variant="body2" sx={{ ml: 1, mb: 2 }}>
                      キーワード検索設定
                    </Typography>
                  </Box>
                  <HeaderSearchAreaSettingRadioGroup
                    label="検索範囲"
                    itemList={[
                      { name: "タイトルと本文", value: "fulltext" },
                      { name: "タイトルのみ", value: "title" },
                    ]}
                    value={articleSearchParams.scope}
                    onChange={(event) =>
                      dispatch(
                        onChangeArticleSearchParams({
                          scope: event.target.value,
                        })
                      )
                    }
                  />
                  <Divider sx={{ my: 2 }} />
                  <HeaderSearchAreaSettingRadioGroup
                    label="複数キーワード検索時"
                    itemList={[
                      { name: "AND", value: "AND" },
                      { name: "OR", value: "OR" },
                    ]}
                    value={articleSearchParams.or}
                    onChange={(event) =>
                      dispatch(
                        onChangeArticleSearchParams({ or: event.target.value })
                      )
                    }
                  />
                </Card>
                <Box
                  sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}
                >
                  <Button
                    onClick={() => dispatch(onClickClearArticleSearchParams())}
                  >
                    reset
                  </Button>
                  <Button
                    variant="contained"
                    onClick={onClickArticleSearch}
                    sx={{ width: 86, ml: 2 }}
                  >
                    検索
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </ClickAwayListener>
      </Paper>
    </Popper>
  );
});

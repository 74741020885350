// // 共通Component_アバター
import { memo } from "react";
import { Avatar } from "@mui/material";

// Avator画像無時のイニシャル表示
const initialName = (display_name) => {
  return `${display_name.slice(0, 1).toUpperCase()}${display_name
    .substr(display_name.indexOf(" ") + 1)
    .slice(0, 1)
    .toUpperCase()}`;
};

export const CustomAvatar = memo((props) => {
  const { data, anchorref, onClick, sx } = props;

  return (
    <Avatar ref={anchorref} src={data.user_avatar} onClick={onClick} sx={sx}>
      {initialName(data.display_name)}
    </Avatar>
  );
});

// 記事ID指定単体取得
import { useQuery } from "react-query";
import { client } from "../utils/authClient";

const getArticle = async (id) => {
  const { data } = await client.get(`/article/${id}`);
  return data;
};

export const useQueryArticleApi = (id) => {
  return useQuery({
    queryKey: ["article", id],
    queryFn: () => id && getArticle(id),
    refetchOnMount: true,
    cacheTime: 0,
    staleTime: 0,
  });
};

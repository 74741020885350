// タグリスト取得
import { useQuery } from "react-query";
import { client } from "../utils/authClient";

const getTag = async () => {
  const { data } = await client.get("/tag/");
  return data;
};

export const useQueryTagApi = () => {
  return useQuery({
    queryKey: ["tag"],
    queryFn: getTag,
    cacheTime: 1000 * 60 * 5,
    staleTime: 1000 * 60 * 5,
  });
};

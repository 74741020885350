import { configureStore } from "@reduxjs/toolkit";
import accessLogReducer from "./accessLogSlice";
import messageStateReducer from "./messageStateSlice";
import articleStateReducer from "./articleStateSlice";
import notificationStateReducer from "./notificationStateSlice";
import otherStateReducer from "./otherStateSlice";
import articleSearchParamsReducer from "./articleSearchParamsSlice";

export const store = configureStore({
  reducer: {
    accessLog: accessLogReducer,
    messageState: messageStateReducer,
    articleState: articleStateReducer,
    articleSearchParams: articleSearchParamsReducer,
    notificationState: notificationStateReducer,
    otherState: otherStateReducer,
  },
});

// 一般/管理者共通_お知らせ確認Dialog
import { memo, useRef } from "react";
import { Divider } from "@mui/material";
import { TextEditor } from "../Share/TextEditor";
import { ArticleNotificationDialogTemplate } from "../Share/ArticleNotificationDialogTemplate ";
import { NotificationDialogAdminMenu } from "./NotificationDialogAdminMenu";
import { NotificationDialogTitleArea } from "./NotificationDialogTitleArea";
import { useQueryMeApi } from "../../hooks/useQueryMeApi";
import { LoadingCircular } from "../Share/LoadingCircular";

export const NotificationDialog = memo((props) => {
  const { open, onClickClose, data } = props;
  const me = useQueryMeApi();
  const editorCore = useRef(null);

  return (
    <ArticleNotificationDialogTemplate
      open={open}
      onClickClose={onClickClose}
      titleContent={
        data &&
        me.data && (
          <>
            {me.data.is_staff && <NotificationDialogAdminMenu data={data} />}
            <NotificationDialogTitleArea data={data} />
            <Divider sx={{ mt: 1 }} />
          </>
        )
      }
      bodyContent={
        data && me.data ? (
          <TextEditor
            readOnly
            editorCore={editorCore}
            defaultValue={data && JSON.parse(data.text)}
          />
        ) : (
          <LoadingCircular />
        )
      }
    />
  );
});

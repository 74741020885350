// 記事閲覧_自分の記事Menu/管理者Menu共通_記事削除確認Dialog
import { memo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { UpdateDbDialog } from "../Share/UpdateDbDialog";
import {
  handleMyArticleDialog,
  handleDeleteArticleState,
  handleAdminArticleDialog,
} from "../../features/articleStateSlice";
import { useMutateArticleApi } from "../../hooks/useMutateArticleApi";

export const DeleteArticleCheckDialog = memo(({ id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const { deleteArticleState, previousURL } = useSelector(
    (state) => state.articleState
  );
  const { deleteArticleMutation, adminDeleteArticleMutation } =
    useMutateArticleApi();

  return (
    <>
      <UpdateDbDialog
        open={deleteArticleState.dialog}
        onClickCloseDialog={() =>
          dispatch(handleDeleteArticleState({ dialog: false }))
        }
        content={<Typography color="error">この記事を削除します</Typography>}
        onClickUpdate={() => {
          pathname.includes("/admin/article")
            ? adminDeleteArticleMutation.mutate(id)
            : deleteArticleMutation.mutate(id);
        }}
        updating={deleteArticleState.updating}
        updated={deleteArticleState.updated}
        onClickCheckUpdated={() => {
          dispatch(handleDeleteArticleState({ dialog: false, updated: false }));
          if (pathname == `/article_search/${id}`) {
            previousURL
              ? navigate(previousURL)
              : navigate("/my_page/my_article/index1");
          } else {
            pathname.includes("/admin/article")
              ? dispatch(handleAdminArticleDialog())
              : dispatch(handleMyArticleDialog());
          }
        }}
      />
    </>
  );
});

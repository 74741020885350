// // 共通Component_記事・ニュース共通Dialog
import { memo } from "react";
import {
  Dialog,
  DialogTitle,
  Box,
  DialogContent,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const ArticleNotificationDialogTemplate = memo((props) => {
  const { open, onClickClose, titleContent, bodyContent, sx } = props;

  return (
    <Dialog
      open={open}
      fullScreen
      sx={{
        ...sx,
        maxHeight: { sm: "100vh", md: "94vh" },
        maxWidth: { sm: "100vw", md: "80vw" },
        margin: "auto",
      }}
    >
      <DialogTitle>
        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
          <Box sx={{ pl: { sm: 0, md: 4 }, flexGrow: 1 }}>{titleContent}</Box>
          <IconButton onClick={onClickClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>{bodyContent}</DialogContent>
    </Dialog>
  );
});

// あなたへのお知らせを既読に変更Mutate
import { useMutation, useQueryClient } from "react-query";
import { client } from "../utils/authClient";

export const useMutateMessageApi = () => {
  const queryClient = useQueryClient();

  //  既読Patch
  const patchIsReadMessageMutation = useMutation(
    (id) => client.patch(`/message/${id}/`, { is_read: true }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["message_list"]);
      },
    }
  );

  return { patchIsReadMessageMutation };
};

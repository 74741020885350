// Header：未来ロゴ・APP名（KnowledgeCampus）
import { memo } from "react";
import { Box, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Logo from "../../../Images/logo.png";

export const HeaderAppName = memo(({ loading }) => {
  const navigate = useNavigate();
  const onClickAppName = () => {
    navigate("/");
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        cursor: !loading && "pointer",
        width: 352,
        pointerEvents: loading && "none",
      }}
      onClick={onClickAppName}
    >
      <img src={Logo} width="42" height="42" />
      <Typography color="primary" variant="h5" sx={{ ml: 2 }}>
        Knowledge Campus
      </Typography>
    </Box>
  );
});

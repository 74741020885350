// アクセスログID指定単体取得
import { useQuery } from "react-query";
import { client } from "../utils/authClient";

const getAccessLogDetails = async (id) => {
  if (id) {
    const { data } = await client.get(`/adm_access_log/${id}/`);
    return data;
  }
};

export const useQueryAccessLogDetailsApi = (id) => {
  return useQuery({
    queryKey: ["access_log_details", id],
    queryFn: () => getAccessLogDetails(id),
    cacheTime: 0,
    staleTime: 0,
  });
};

// プロフィール_アバター画像変更Dialog
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Avatar } from "@mui/material";
import { UpdateDbDialog } from "../Share/UpdateDbDialog";
import { MyPageProfileAreaUploadButton } from "./MyPageProfileAreaUploadButton";

export const MyPageProfilePutAvatorDialog = memo((props) => {
  const {
    newAvatar,
    onChangeAddImage,
    handleEditAvatarDialog,
    putAvatarState,
    onClickPutAvatar,
  } = props;
  const navigate = useNavigate();

  return (
    <UpdateDbDialog
      open={putAvatarState.dialog}
      onClickCloseDialog={handleEditAvatarDialog}
      content={
        <Box>
          <Avatar
            src={newAvatar.length ? URL.createObjectURL(newAvatar[0]) : null}
            sx={{ height: 100, width: 100, mb: 1 }}
          />
          <MyPageProfileAreaUploadButton onChange={onChangeAddImage}>
            画像を選択
          </MyPageProfileAreaUploadButton>
        </Box>
      }
      onClickUpdate={onClickPutAvatar}
      updating={putAvatarState.updating}
      updated={putAvatarState.updated}
      onClickCheckUpdated={() => navigate(0)}
      disabled={!newAvatar.length}
    />
  );
});

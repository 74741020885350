// 管理者用)お知らせ管理_お知らせ管理テーブル
import { memo } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import DetailsIcon from "@mui/icons-material/Details";
import CheckIcon from "@mui/icons-material/Check";
import { CustomDataGrid } from "../Share/CustomDataGrid";
import { useQueryAdmNotificationListApi } from "../../hooks/useQueryAdmNotificationListApi";
import { handleAdminNotificationDialog } from "../../features/notificationStateSlice";

export const AdminNotificationTable = memo(() => {
  const dispatch = useDispatch();
  const { index } = useParams();
  const { data, isLoading } = useQueryAdmNotificationListApi(index);

  const columns = [
    {
      field: "check",
      headerName: "管理",
      headerAlign: "center",
      maxWidth: 52,
      minWidth: 52,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (row) => {
        return (
          <IconButton
            onClick={() => dispatch(handleAdminNotificationDialog(row.row.id))}
          >
            <DetailsIcon />
          </IconButton>
        );
      },
    },
    { field: "id", width: 72, align: "right" },
    { field: "title", headerName: "タイトル", width: 282, sortable: false },
    {
      field: "show",
      headerName: "公開",
      headerAlign: "center",
      maxWidth: 52,
      minWidth: 52,
      align: "center",
      disableColumnMenu: true,
      renderCell: (row) => {
        return row.row.is_active && <CheckIcon color="error" />;
      },
    },
    { field: "start_date", headerName: "開始日", width: 108 },
    { field: "end_date", headerName: "終了日", width: 108 },
    { field: "updated_at", headerName: "更新日", width: 150 },
    { field: "created_at", headerName: "作成日", width: 150 },
  ];

  return (
    <CustomDataGrid
      rows={isLoading || !data ? [] : data.results}
      columns={columns}
      loading={isLoading}
    />
  );
});

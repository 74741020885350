// 管理者用)お知らせ管理_お知らせ確認Dialog
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useQueryAdmNotificationApi } from "../../hooks/useQueryAdmNotificationApi";
import { NotificationDialog } from "../NotificationDialog/NotificationDialog";
import { handleAdminNotificationDialog } from "../../features/notificationStateSlice";

export const AdminNotificationDialog = memo(() => {
  const dispatch = useDispatch();
  const { adminNotificationDialog } = useSelector(
    (state) => state.notificationState
  );
  const { data } = useQueryAdmNotificationApi(adminNotificationDialog.id);

  return (
    <NotificationDialog
      open={adminNotificationDialog.dialog}
      onClickClose={() => dispatch(handleAdminNotificationDialog())}
      data={data}
    />
  );
});

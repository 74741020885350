// Header_右側Avatarクリック時Profile/SignOut
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Chip, Divider, Badge, Button } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import GroupsIcon from "@mui/icons-material/Groups";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { msalInstance } from "../../..";
import { useQueryMeApi } from "../../../hooks/useQueryMeApi";
import { CustomAvatar } from "../../Share/CustomAvatar";

// SignOut
const onClickSignOut = async () => {
  const currentAccount = msalInstance.getAllAccounts()[0];
  await msalInstance.logoutPopup({
    account: currentAccount,
    postLogoutRedirectUri: "/",
    mainWindowRedirectUri: "/",
  });
};

export const HeaderAvatarProfile = memo(() => {
  const navigate = useNavigate();
  const { data, isLoading } = useQueryMeApi();

  if (isLoading) return;

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {data.is_staff && <Chip label="Admin" sx={{ mr: 1 }} />}
          <Typography sx={{ textAlign: "center" }}>
            {data.display_name}
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ mb: 2 }}>
        <Badge overlap="circular">
          <CustomAvatar data={data} sx={{ height: 74, width: 74 }} />
        </Badge>
      </Divider>
      <Box>
        {/* <Box sx={{ display: "flex", alignItems: "center", height: 18 }}>
          <GroupsIcon fontSize="small" />
        </Box> */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: 18,
          }}
        >
          <MailOutlineIcon fontSize="small" />
          <Typography variant="body2" sx={{ ml: 1 }}>
            {data.email}
          </Typography>
        </Box>
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Button onClick={() => navigate("/my_page/profile")}>My Page</Button>
        <Button
          variant="contained"
          startIcon={<ExitToAppIcon />}
          onClick={onClickSignOut}
        >
          Sign out
        </Button>
      </Box>
    </>
  );
});

// お知らせ関連State
import { createSlice } from "@reduxjs/toolkit";
import { today } from "../utils/datetime";

const defaultParamsState = {
  title: "",
  start_date: null,
  end_date: null,
  is_active: true,
};

const defaultFetchState = {
  dialog: false,
  updating: false,
  updated: false,
};

const initialState = {
  notificationDialog: { id: "", dialog: false },
  adminNotificationDialog: { id: "", dialog: false },
  createNotificationDialog: false,
  editNotificationDialog: false,
  notificationParams: defaultParamsState,
  postNotificationState: defaultFetchState,
  putNotificationState: defaultFetchState,
  patchIsActiveNotificationState: defaultFetchState,
  deleteNotificationState: defaultFetchState,
  createNotificationPreviewTab: 0,
  editNotificationPreviewTab: 0,
};

const notificationStateSlice = createSlice({
  name: "notificationState",
  initialState,
  reducers: {
    // ***onChange:お知らせ作成・編集***
    onChangeNotificationParams: (state, actions) => {
      state.notificationParams = {
        ...state.notificationParams,
        ...actions.payload,
      };
    },

    // *****Dialog開閉*****
    // Dialogを開いた際に必要な初期値を引数に取り、記事内容確認・投稿・編集内容Stateへ格納する
    // 新規投稿作成開く
    onClickCreateNotificationDialogOpen: (state) => {
      state.notificationParams = {
        ...defaultParamsState,
        start_date: today,
      };
      state.createNotificationDialog = true;
    },
    // コピー新規開く
    onClickCopyCreateNotificationDialogOpen: (state, actions) => {
      state.notificationParams = {
        ...actions.payload,
        start_date: today,
        end_date: null,
      };
      state.createNotificationDialog = true;
    },
    //　新規投稿閉じる
    onClickCreateNotificationDialogClose: (state) => {
      state.createNotificationDialog = false;
      state.createNotificationPreviewTab = 0;
    },
    // 編集
    handleEditNotificationDialog: (state, actions) => {
      if (!state.editNotificationDialog) {
        state.notificationParams = actions.payload;
      } else {
        state.editNotificationPreviewTab = 0;
      }
      state.editNotificationDialog = !state.editNotificationDialog;
    },
    //  公開・非公開切替Patch
    handlePatchIsActiveNotificationDialog: (state) => {
      state.patchIsActiveNotificationState = {
        ...state.patchIsActiveNotificationState,
        dialog: !state.patchIsActiveNotificationState.dialog,
      };
    },
    //  お知らせ閲覧Dialog開閉
    handleNotificationDialog: (state, actions) => {
      if (!state.notificationDialog.dialog) {
        state.notificationDialog = { id: actions.payload, dialog: true };
      } else {
        state.notificationDialog = {
          ...state.notificationDialog,
          dialog: false,
        };
      }
    },
    //  管理者Pageお知らせ確認Dialog開閉
    handleAdminNotificationDialog: (state, actions) => {
      if (!state.adminNotificationDialog.dialog) {
        state.adminNotificationDialog = { id: actions.payload, dialog: true };
      } else {
        state.adminNotificationDialog = {
          ...state.adminNotificationDialog,
          dialog: false,
        };
      }
    },
    // *****API更新処理関連*****
    //  Post
    handlePostNotificationState: (state, actions) => {
      state.postNotificationState = {
        ...state.postNotificationState,
        ...actions.payload,
      };
    },
    //  Put
    handlePutNotificationState: (state, actions) => {
      state.putNotificationState = {
        ...state.putNotificationState,
        ...actions.payload,
      };
    },
    //  公開・非公開切替Patch
    handlePatchIsActiveNotificationState: (state, actions) => {
      state.patchIsActiveNotificationState = {
        ...state.patchIsActiveNotificationState,
        ...actions.payload,
      };
    },
    // Delete
    handleDeleteNotificationState: (state, actions) => {
      state.deleteNotificationState = {
        ...state.deleteNotificationState,
        ...actions.payload,
      };
    },
    // *****その他*****
    // Create/Preview切替Tab操作
    handleCreateNotificationPreviewTab: (state, actions) => {
      state.createNotificationPreviewTab = actions.payload;
    },
    // Edit/Preview切替Tab操作
    handleEditNotificationPreviewTab: (state, actions) => {
      state.editNotificationPreviewTab = actions.payload;
    },
    // 新規投稿・編集完了時の編集内容クリア
    onClickClearNotificationParams: (state) => {
      state.notificationParams = defaultParamsState;
    },
  },
});

export const {
  onChangeNotificationParams,
  handleNotificationDialog,
  handleAdminNotificationDialog,
  onClickCreateNotificationDialogOpen,
  onClickCopyCreateNotificationDialogOpen,
  onClickCreateNotificationDialogClose,
  handleEditNotificationDialog,
  handlePatchIsActiveNotificationDialog,
  handlePostNotificationState,
  handlePutNotificationState,
  handlePatchIsActiveNotificationState,
  handleDeleteNotificationState,
  handleCreateNotificationPreviewTab,
  handleEditNotificationPreviewTab,
  onClickClearNotificationParams,
} = notificationStateSlice.actions;

export default notificationStateSlice.reducer;

// あなたへのお知らせ_あなたへのお知らせリスト_あなたへのお知らせ確認Dialog
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  Typography,
  Box,
  DialogContent,
  IconButton,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { handleMessageDialog } from "../../features/messageStateSlice";
import { useQueryMessageApi } from "../../hooks/useQueryMessageApi";
import { LoadingCircular } from "../Share/LoadingCircular";
import { messageTemplate } from "../../Containers/MyPageMessageTemplate";

export const MyPageMessageDialog = memo(() => {
  const dispatch = useDispatch();
  const { messageDialog } = useSelector((state) => state.messageState);
  const { data } = useQueryMessageApi(messageDialog.id);

  return (
    <Dialog
      open={messageDialog.dialog}
      fullWidth
      maxWidth="md"
      onClose={() => dispatch(handleMessageDialog())}
    >
      <DialogTitle>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "base.contrastText",
          }}
        >
          <NotificationsIcon />
          <Typography variant="h7" sx={{ ml: 1, flexGrow: 1 }}>
            あなたへのお知らせ
          </Typography>
          <IconButton onClick={() => dispatch(handleMessageDialog())}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider sx={{ my: 1 }} />
      </DialogTitle>
      <DialogContent sx={{ height: 266 }}>
        {data ? (
          <Box>
            <Typography variant="subtitle2">{data.send_at}</Typography>
            <Typography variant="h6" color="primary" textAlign="center">
              {messageTemplate(data.text).subject}
            </Typography>
            <Box sx={{ mt: 2 }}>{messageTemplate(data.text).text}</Box>
          </Box>
        ) : (
          <LoadingCircular />
        )}
      </DialogContent>
    </Dialog>
  );
});

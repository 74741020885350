// 管理者用)記事リスト取得
import { useQuery } from "react-query";
import { client } from "../utils/authClient";

const getAdmArticleList = async (page) => {
  const { data } = await client.get("/adm_article/", {
    params: {
      page: page,
      page_size: 20,
    },
  });
  return data;
};

export const useQueryAdmArticleListApi = (page) => {
  return useQuery({
    queryKey: ["adm_article_list", page],
    queryFn: () => getAdmArticleList(page),
    cacheTime: 1000 * 60 * 3,
    staleTime: 0,
  });
};

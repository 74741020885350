// Header：右側通知件数表示部分
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { Badge } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";

export const HeaderMessageBadge = memo((props) => {
  const navigate = useNavigate();
  const { data, loading } = props;

  const unreadMessage = data && data.results.filter((x) => !x.is_read);

  return (
    <Badge
      badgeContent={data ? unreadMessage.length : null}
      color="error"
      sx={{
        mr: 3,
        cursor: "pointer",
        pointerEvents: loading && "none",
        opacity: loading && 0.6,
      }}
      onClick={() => navigate("/my_page/message")}
    >
      <NotificationsIcon fontSize="large" sx={{ color: "primary.main" }} />
    </Badge>
  );
});

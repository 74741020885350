import { useEffect } from "react";
import { Outlet } from "react-router-dom";
import {
  InteractionType,
  InteractionRequiredAuthError,
  BrowserAuthError,
} from "@azure/msal-browser";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsalAuthentication,
} from "@azure/msal-react";
import { loginRequest } from "./utils/authConfig";
import { Box } from "@mui/material";
import { Header } from "./Components/PageLayout/Header";
import { UnAuth } from "./Containers/UnAuth";
import Logo from "./Images/logo.png";
import { SideMenu } from "./Components/PageLayout/SideMenu";
import { CreateArticleDialog } from "./Components/ArticleDialog/CreateArticleDialog";
import { PostArticleCheckDialog } from "./Components/ArticleDialog/PostArticleCheckDialog";
import { OwnerDialog } from "./Components/Share/OwnerDialog";

export const PageLayout = () => {
  const { login, error } = useMsalAuthentication(
    InteractionType.Silent,
    loginRequest
  );

  useEffect(() => {
    if (error && error instanceof InteractionRequiredAuthError) {
      login(InteractionType.Popup, loginRequest).catch((err) => {
        if (
          err instanceof BrowserAuthError &&
          (err.errorCode === "popup_window_error" ||
            err.errorCode === "empty_window_error")
        ) {
          login(InteractionType.Redirect, loginRequest);
        }
      });
    }
  }, [error]);
  return (
    <>
      <UnauthenticatedTemplate>
        <UnAuth />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        <CreateArticleDialog />
        <PostArticleCheckDialog />
        <OwnerDialog />
        <Header />
        <Box sx={{ display: "flex" }}>
          <SideMenu />
          <Box
            sx={{
              pt: 10,
              px: 1,
              pb: 1,
              height: "100vh",
              overflow: "hidden",
              position: "relative",
              flexGrow: 1,
              bgcolor: "base.bg",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                right: 0,
                top: 52,
                opacity: 0.32,
              }}
            >
              <img src={Logo} height={462} width={462} />
            </Box>
            <Box
              sx={{
                position: "relative",
                zIndex: 1,
                height: "100%",
              }}
            >
              <Outlet />
            </Box>
          </Box>
        </Box>
      </AuthenticatedTemplate>
    </>
  );
};

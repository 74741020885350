// お知らせ確認Dialog_管理者Menu_お知らせ削除確認Dialog
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { UpdateDbDialog } from "../Share/UpdateDbDialog";
import {
  handleAdminNotificationDialog,
  handleDeleteNotificationState,
  handleNotificationDialog,
} from "../../features/notificationStateSlice";
import { useMutateNotificationApi } from "../../hooks/useMutateNotificationApi";

export const DeleteNotificationCheckDialog = memo(({ id }) => {
  const dispatch = useDispatch();
  const {
    deleteNotificationState,
    notificationDialog,
    adminNotificationDialog,
  } = useSelector((state) => state.notificationState);
  const { deleteNotificationMutation } = useMutateNotificationApi();

  return (
    <>
      <UpdateDbDialog
        open={deleteNotificationState.dialog}
        onClickCloseDialog={() =>
          dispatch(handleDeleteNotificationState({ dialog: false }))
        }
        content={
          <Typography color="error">このお知らせを削除します</Typography>
        }
        onClickUpdate={() => deleteNotificationMutation.mutate(id)}
        updating={deleteNotificationState.updating}
        updated={deleteNotificationState.updated}
        onClickCheckUpdated={() => {
          dispatch(
            handleDeleteNotificationState({ dialog: false, updated: false })
          ),
            notificationDialog.dialog && dispatch(handleNotificationDialog());
          adminNotificationDialog.dialog &&
            dispatch(handleAdminNotificationDialog());
        }}
      />
    </>
  );
});

// 記事閲覧_自分の記事Menu
import { memo, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  Typography,
  Box,
  Chip,
  Button,
  FormControlLabel,
  Switch,
  Tooltip,
  Zoom,
  Divider,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  handleDeleteArticleState,
  handleEditArticleDialog,
  handlePatchIsPublishedArticleDialog,
  onChangeArticleParams,
  onClickCopyCreateArticleDialogOpen,
} from "../../features/articleStateSlice";
import { PatchIsPubulishedArticleCheckDialog } from "../ArticleDialog/PatchIsPubulishedArticleCheckDialog";
import { EditArticleDialog } from "../ArticleDialog/EditArticleDialog";
import { PutArticleCheckDialog } from "../ArticleDialog/PutArticleCheckDialog";

export const ArticleMyArticleMenu = memo(({ data }) => {
  const dispatch = useDispatch();
  const [isPublished, setIsPublished] = useState(data.is_published);

  const onChangeArticleIsPublished = useCallback(() => {
    setIsPublished(!isPublished);
    dispatch(handlePatchIsPublishedArticleDialog());
  }, [isPublished]);

  return (
    <>
      <EditArticleDialog defaultTags={data.tags} />
      <PutArticleCheckDialog />
      <PatchIsPubulishedArticleCheckDialog
        params={{ id: data.id, is_published: !data.is_published }}
        onChangeArticleIsPublished={onChangeArticleIsPublished}
      />
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Chip label="自分の記事" color="error" variant="outlined" />
        <FormControlLabel
          sx={{ width: 108, ml: 0.6 }}
          control={
            !data.is_banned ? (
              <Switch
                checked={isPublished}
                onChange={onChangeArticleIsPublished}
              />
            ) : (
              <Tooltip
                title={
                  <Box sx={{ textAlign: "center" }}>
                    <Typography variant="body2">
                      管理者による公開禁止措置中は
                    </Typography>
                    <Typography variant="body2">
                      ここで公開にしても実際に公開されません
                    </Typography>
                  </Box>
                }
                TransitionComponent={Zoom}
              >
                <Switch
                  checked={isPublished}
                  onChange={onChangeArticleIsPublished}
                />
              </Tooltip>
            )
          }
          label={<Typography>{isPublished ? "公開" : "非公開"}</Typography>}
        />
        <Button
          variant="contained"
          startIcon={<EditIcon />}
          onClick={() => dispatch(handleEditArticleDialog(data))}
        >
          編集
        </Button>
        <Button
          variant="contained"
          sx={{ ml: 1.6 }}
          startIcon={<DeleteIcon />}
          onClick={() => {
            dispatch(onChangeArticleParams({ id: data.id }));
            dispatch(handleDeleteArticleState({ dialog: true }));
          }}
          color="error"
        >
          削除
        </Button>
        <Button
          variant="contained"
          startIcon={<ContentCopyIcon />}
          onClick={() => dispatch(onClickCopyCreateArticleDialogOpen(data))}
          sx={{
            display: { xs: "none", sm: "flex" },
            ml: 1.6,
          }}
        >
          複写新規作成
        </Button>
      </Box>
      <Divider sx={{ mt: 0.6, mb: 1 }} />
    </>
  );
});

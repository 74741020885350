// アクセスログリスト取得
import { useQuery } from "react-query";
import { client } from "../utils/authClient";

const getAccessLog = async (page) => {
  const { data } = await client.get("/adm_access_log/", {
    params: { page: page },
  });
  return data;
};

export const useQueryAccessLogApi = (page) => {
  return useQuery({
    queryKey: ["access_log", page],
    queryFn: () => getAccessLog(page),
    cacheTime: 1000 * 60 * 3,
    staleTime: 0,
  });
};

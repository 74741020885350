// 管理者用)アクセスログ_アクセスログ確認テーブル
import { memo } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import DetailsIcon from "@mui/icons-material/Details";
import { CustomDataGrid } from "../Share/CustomDataGrid";
import { useQueryAccessLogApi } from "../../hooks/useQueryAccessLogApi";
import { handleAccessLogDialog } from "../../features/accessLogSlice";

export const AdminAccessLogTable = memo(() => {
  const dispatch = useDispatch();
  const { index } = useParams();
  const { data, isLoading } = useQueryAccessLogApi(index);

  const columns = [
    {
      field: "detail",
      headerName: "詳細",
      headerAlign: "center",
      maxWidth: 52,
      minWidth: 52,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (row) => {
        return (
          <IconButton
            onClick={() => dispatch(handleAccessLogDialog(row.row.id))}
          >
            <DetailsIcon />
          </IconButton>
        );
      },
    },
    { field: "id", width: 72, align: "right" },
    { field: "oid", width: 328 },
    { field: "email", width: 248 },
    { field: "origin", width: 182 },
    { field: "request_method", headerName: "method", width: 78 },
    { field: "request_path", width: 260 },
    { field: "response_status", headerName: "status", width: 60 },
    { field: "accessed_at", width: 150 },
  ];

  return (
    <CustomDataGrid
      rows={isLoading || !data ? [] : data.results}
      columns={columns}
      loading={isLoading}
    />
  );
});

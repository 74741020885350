// 共通Component_DataGrid
import { memo } from "react";
import { DataGrid } from "@mui/x-data-grid";

export const CustomDataGrid = memo((props) => {
  const { rows, columns, loading } = props;

  return (
    <DataGrid
      sx={{
        fontSize: 16,
        "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
          outline: "none",
        },
        "& .MuiDataGrid-columnHeader": {
          fontSize: 14,
        },
      }}
      rows={rows}
      columns={columns}
      headerHeight={32}
      rowHeight={38}
      disableSelectionOnClick
      disableColumnSelector
      loading={loading}
      hideFooter
    />
  );
});

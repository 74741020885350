// お知らせ確認Dialog_周知日・タイトル
import { memo } from "react";
import { Typography, Box } from "@mui/material";
import FiberNewIcon from "@mui/icons-material/FiberNew";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import { dateFormatter, isRecently } from "../../utils/datetime";

export const NotificationDialogTitleArea = memo(({ data }) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          color: "base.contrastText",
          my: 1,
        }}
      >
        <AnnouncementIcon />
        <Typography variant="h7" sx={{ flexGrow: 1, ml: 1 }}>
          お知らせ
        </Typography>
      </Box>
      <Box sx={{ display: "flex", alignItems: "center", height: 26 }}>
        <Typography variant="subtitle2">
          {dateFormatter(data.start_date)}
        </Typography>
        {isRecently(data.start_date) && (
          <FiberNewIcon color="secondary" sx={{ ml: 0.6 }} />
        )}
      </Box>
      <Typography variant="h5" color="primary">
        {data.title}
      </Typography>
    </>
  );
});

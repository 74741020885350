// // 共通Component_LoadingCircular
import { memo } from "react";
import { Grid, Typography, CircularProgress } from "@mui/material";

export const LoadingCircular = memo(() => {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ height: "100%" }}
      spacing={2}
    >
      <Grid item>
        <Typography variant="h6">Loading・・・</Typography>
      </Grid>
      <Grid item>
        <CircularProgress />
      </Grid>
    </Grid>
  );
});

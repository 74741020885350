// 自分のデータ取得
import { useQuery } from "react-query";
import { client } from "../utils/authClient";

const getMe = async () => {
  const { data } = await client.get("/me/");
  return data;
};

export const useQueryMeApi = () => {
  return useQuery({
    queryKey: ["me"],
    queryFn: getMe,
    cacheTime: 10000,
    staleTime: Infinity,
  });
};

// お知らせ確認Dialog_管理者Menu_お知らせ編集Dialog_更新確認Dialog
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { UpdateDbDialog } from "../Share/UpdateDbDialog";
import { useMutateNotificationApi } from "../../hooks/useMutateNotificationApi";
import {
  handleAdminNotificationDialog,
  handleEditNotificationDialog,
  handleNotificationDialog,
  handlePutNotificationState,
  onClickClearNotificationParams,
} from "../../features/notificationStateSlice";

export const PutNotificationCheckDialog = memo(() => {
  const dispatch = useDispatch();

  const { putNotificationState, notificationDialog, adminNotificationDialog } =
    useSelector((state) => state.notificationState);
  const { putNotificationMutation } = useMutateNotificationApi();

  return (
    <UpdateDbDialog
      open={putNotificationState.dialog}
      onClickCloseDialog={() =>
        dispatch(handlePutNotificationState({ dialog: false }))
      }
      content={<Typography color="error">この内容で更新します</Typography>}
      onClickUpdate={() => putNotificationMutation.mutate()}
      updating={putNotificationState.updating}
      updated={putNotificationState.updated}
      onClickCheckUpdated={() => {
        dispatch(onClickClearNotificationParams());
        dispatch(handlePutNotificationState({ dialog: false, updated: false }));
        dispatch(handleEditNotificationDialog());
        notificationDialog.dialog && dispatch(handleNotificationDialog());
        adminNotificationDialog.dialog &&
          dispatch(handleAdminNotificationDialog());
      }}
    />
  );
});

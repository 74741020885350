// 管理者用)access_log関連
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  accessLogDialog: false,
  accessLogId: null,
};

const accessLogSlice = createSlice({
  name: "accessLog",
  initialState,
  reducers: {
    handleAccessLogDialog: (state, actions) => {
      if (!state.accessLogDialog) {
        state.accessLogId = actions.payload;
      }
      state.accessLogDialog = !state.accessLogDialog;
    },
  },
});

export const { handleAccessLogDialog } = accessLogSlice.actions;

export default accessLogSlice.reducer;

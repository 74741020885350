// 記事閲覧_コメント欄操作_コメントリスト_返信コメント作成
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  handleCreateReplyArticleCommentDialog,
  onChangeArticleReplyComment,
} from "../../features/articleStateSlice";
import { useMutateArticleOptionsApi } from "../../hooks/useMutateArticleOptionsApi";

export const CreateReplyArticleCommentDialog = memo(({ id }) => {
  const dispatch = useDispatch();
  const {
    createReplyCommentDialog,
    articleCreateReplyCommentParams,
    articleCommentUpdating,
  } = useSelector((state) => state.articleState);

  const { postArticleReplyCommentMutation } = useMutateArticleOptionsApi();

  return (
    <Dialog open={createReplyCommentDialog} fullWidth maxWidth="sm">
      <DialogContent>
        <TextField
          fullWidth
          minRows={3}
          multiline
          label="このコメントに返信する"
          value={articleCreateReplyCommentParams.text}
          onChange={(event) =>
            dispatch(onChangeArticleReplyComment(event.target.value))
          }
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => dispatch(handleCreateReplyArticleCommentDialog())}
        >
          cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            postArticleReplyCommentMutation.mutate(id);
            dispatch(onChangeArticleReplyComment(""));
            dispatch(handleCreateReplyArticleCommentDialog());
          }}
          disabled={
            !Boolean(articleCreateReplyCommentParams) || articleCommentUpdating
          }
          sx={{ mr: 2 }}
        >
          送信
        </Button>
      </DialogActions>
    </Dialog>
  );
});

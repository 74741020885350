// お知らせリスト取得
import { useQuery } from "react-query";
import { client } from "../utils/authClient";

const getNotificationList = async (page) => {
  const { data } = await client.get("/notification/", {
    params: {
      page: page,
      page_size: 20,
    },
  });
  return data;
};

export const useQueryNotificationListApi = (page) => {
  return useQuery({
    queryKey: ["notification_list", page],
    queryFn: () => getNotificationList(page),
    cacheTime: 1000 * 60 * 3,
    staleTime: 0,
  });
};

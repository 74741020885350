import axios from "axios";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { protectedResources } from "./authConfig";
import { msalInstance } from "../index";

axios.defaults.baseURL = protectedResources.base_url;

export const client = axios.create();

client.interceptors.request.use(async (config) => {
  const account = msalInstance.getAllAccounts()[0];
  await msalInstance
    .acquireTokenSilent({
      scopes: protectedResources.scopes,
      account: account,
    })
    .then((res) => {
      config.headers["Authorization"] = `Bearer ${res.accessToken}`;
    })
    .catch((error) => {
      if (error instanceof InteractionRequiredAuthError) {
        msalInstance
          .acquireTokenRedirect({
            scopes: protectedResources.scopes,
          })
          .then((res) => {
            config.headers["Authorization"] = `Bearer ${res.accessToken}`;
          });
      }
    });
  return config;
});

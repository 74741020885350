// MyPage:プロフィール
import { Box, Typography, Chip, IconButton, Badge } from "@mui/material";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import GroupsIcon from "@mui/icons-material/Groups";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { MyPageProfilePutAvatorDialog } from "../Components/MyPage/MyPageProfilePutAvatorDialog";
import { ContentCard } from "../Components/Share/ContentCard";
import { useQueryMeApi } from "../hooks/useQueryMeApi";
import { useMutateAvatarApi } from "../hooks/useMutateAvatarApi";
import { CustomAvatar } from "../Components/Share/CustomAvatar";

export const MyPageProfile = () => {
  const { data } = useQueryMeApi();
  const {
    newAvatar,
    onChangeAddImage,
    handleEditAvatarDialog,
    putAvatarState,
    putAvatarMutation,
  } = useMutateAvatarApi();

  return (
    <>
      <MyPageProfilePutAvatorDialog
        newAvatar={newAvatar}
        putAvatarState={putAvatarState}
        handleEditAvatarDialog={handleEditAvatarDialog}
        onChangeAddImage={onChangeAddImage}
        onClickPutAvatar={() => putAvatarMutation.mutate(data.id)}
      />

      <ContentCard>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "base.contrastText",
            mb: 3,
          }}
        >
          <AssignmentIndIcon />
          <Typography variant="h7" sx={{ ml: 1 }}>
            プロフィール
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              badgeContent={
                <IconButton onClick={handleEditAvatarDialog}>
                  <ChangeCircleIcon />
                </IconButton>
              }
            >
              <CustomAvatar data={data} sx={{ height: 118, width: 118 }} />
            </Badge>
          </Box>
          <Box ml={4}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                height: 28,
                mb: 0.6,
              }}
            >
              <PersonOutlineIcon />
              <Typography variant="h6" sx={{ ml: 1.8 }}>
                {data.display_name}
              </Typography>
              {data.is_staff && <Chip label="Admin" sx={{ ml: 2 }} />}
            </Box>
            {/* <Box sx={{ display: "flex", alignItems: "center", height: 28 }}>
              <GroupsIcon />
            </Box> */}
            <Box sx={{ display: "flex", alignItems: "center", height: 28 }}>
              <MailOutlineIcon />
              <Typography sx={{ ml: 1.8 }}>{data.email}</Typography>
            </Box>
          </Box>
        </Box>
      </ContentCard>
    </>
  );
};

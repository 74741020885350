// お知らせ記事_新規作成・編集Dialog_Preview_Text部分
import { memo } from "react";
import { TextEditor } from "../Share/TextEditor";

export const CreateEditNotificationDialogPreviewText = memo(
  ({ previewText }) => {
    return (
      <TextEditor
        defaultValue={previewText}
        editorCore={previewText}
        readOnly
      />
    );
  }
);

// 共通Component_API更新時共通Dialog
// 1.更新しますか？
// 2.更新OKでAPIリクエスト処理開始しupdatingをtrueにする=更新中のDialogが起動
// 3.更新が終わるとupdatingがfalseになり、updatedがtrueになる=更新完了のDialogが起動
// 4.更新完了画面でOKを押すとリロードなど各ページで更新完了時に必要な処理を行う
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";

export const UpdateDbDialog = (props) => {
  const {
    open,
    onClickCloseDialog,
    content,
    onClickUpdate,
    updating,
    updated,
    onClickCheckUpdated,
    disabled,
  } = props;
  return (
    <>
      {/* 1.更新しますか？ */}
      <Dialog open={open}>
        <DialogContent>{content}</DialogContent>
        <DialogActions>
          <Button disabled={disabled} onClick={onClickUpdate}>
            OK
          </Button>
          <Button onClick={onClickCloseDialog}>Cancel</Button>
        </DialogActions>
      </Dialog>
      {/* 2.更新OKでAPIリクエスト処理開始しupdatingをtrueにする=更新中のDialogが起動 */}
      <Dialog open={updating}>
        <DialogContent>
          <Box textAlign="center">
            <Typography sx={{ mb: 1 }}>更新中</Typography>
            {updating && <CircularProgress />}
          </Box>
        </DialogContent>
      </Dialog>
      {/* 3.更新が終わるとupdatingがfalseになり、updatedがtrueになる=更新完了のDialogが起動 */}
      <Dialog open={updated}>
        <DialogContent>
          <Box textAlign="center">
            <Typography sx={{ mb: 1 }}>更新完了</Typography>
            {/* 4.更新完了画面でOKを押すとリロードなど各ページで更新完了時に必要な処理を行う */}
            <Button onClick={onClickCheckUpdated}>OK</Button>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

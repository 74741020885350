// 記事確認別タブ用（自分の記事・管理者でも記事操作を抑止）
import { useRef } from "react";
import { useParams } from "react-router-dom";
import { Box, Divider, Typography } from "@mui/material";
import { TextEditor } from "../Components/Share/TextEditor";
import { useQueryArticleApi } from "../hooks/useQueryArticleApi";
import { ArticleTitleArea } from "../Components/Article/ArticleTitleArea";
import { ContentCard } from "../Components/Share/ContentCard";
import { ArticleCommentsPostArea } from "../Components/Article/ArticleCommentsPostArea";
import { ArticleCommentsList } from "../Components/Article/ArticleCommentsList";
import { EditArticleCommentDialog } from "../Components/Article/EditArticleCommentDialog";
import { DeleteArticleCommentCheckDialog } from "../Components/Article/DeleteArticleCommentCheckDialog";
import { CreateReplyArticleCommentDialog } from "../Components/Article/CreateReplyArticleCommentDialog";
import { useQueryMeApi } from "../hooks/useQueryMeApi";
import Logo from "../Images/logo.png";
import { ScrollBox } from "../Components/Share/ScrollBox";
import { LoadingCircular } from "../Components/Share/LoadingCircular";

export const ArticleForAnotherTab = () => {
  const { id } = useParams();
  const article = useQueryArticleApi(id);
  const me = useQueryMeApi();
  const editorCore = useRef(null);

  if (article.isLoading || me.isLoading) {
    return (
      <Box sx={{ height: "100vh" }}>
        <LoadingCircular />
      </Box>
    );
  }

  if (!article.data) {
    return (
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box sx={{ textAlign: "center" }}>
          <Typography>記事が見つかりませんでした</Typography>
          <Typography>
            （この記事は削除されたか、非公開の可能性があります）
          </Typography>
        </Box>
      </Box>
    );
  }
  return (
    <>
      <CreateReplyArticleCommentDialog id={id} />
      <EditArticleCommentDialog id={id} />
      <DeleteArticleCommentCheckDialog id={id} />
      <Box
        sx={{
          height: "100vh",
          px: { xs: 0, sm: 2, md: 12, lg: 28 },
          position: "relative",
          bgcolor: "base.bg",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            right: -122,
            top: -38,
            opacity: 0.32,
          }}
        >
          <img src={Logo} height={562} width={562} />
        </Box>
        <Box sx={{ position: "relative", zIndex: 1, height: "100%", p: 1 }}>
          <ContentCard sx={{ px: { xs: 2, sm: 4, lg: 6 }, pt: 4 }}>
            <ArticleTitleArea data={article.data} />
            <Divider sx={{ mt: 1 }} />
            <ScrollBox>
              <TextEditor
                readOnly
                editorCore={editorCore}
                defaultValue={JSON.parse(article.data.text)}
              />

              <Divider sx={{ fontSize: 14, mx: 2 }}>Comments</Divider>
              <Box sx={{ px: { xs: 1, lg: 4 } }}>
                <ArticleCommentsPostArea id={id} />
                <ArticleCommentsList id={id} />
              </Box>
            </ScrollBox>
          </ContentCard>
        </Box>
      </Box>
    </>
  );
};

// 全てのお知らせ_お知らせリスト_お知らせ確認Dialog
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NotificationDialog } from "../NotificationDialog/NotificationDialog";
import { handleNotificationDialog } from "../../features/notificationStateSlice";
import { useQueryNotificationApi } from "../../hooks/useQueryNotificationApi";

export const HomeNotificationDialog = memo(() => {
  const dispatch = useDispatch();
  const { notificationDialog } = useSelector(
    (state) => state.notificationState
  );
  const { data } = useQueryNotificationApi(notificationDialog.id);

  return (
    <NotificationDialog
      open={notificationDialog.dialog}
      onClickClose={() => dispatch(handleNotificationDialog())}
      data={data}
    />
  );
});

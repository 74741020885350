// 検索結果画面_記事リスト_チラ見Dialog
import { memo, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog,
  DialogTitle,
  Box,
  DialogContent,
  IconButton,
  Divider,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { useQueryArticleApi } from "../../hooks/useQueryArticleApi";
import { handleGlimpseArticleDialog } from "../../features/articleStateSlice";
import { LoadingCircular } from "../Share/LoadingCircular";
import { TextEditor } from "../Share/TextEditor";

export const ArticleSearchGlimpseAricleDialog = memo(() => {
  const dispatch = useDispatch();
  const editorCore = useRef(null);
  const { glimpseArticleDialog } = useSelector((state) => state.articleState);
  const { data, isLoading } = useQueryArticleApi(glimpseArticleDialog.id);

  return (
    <Dialog
      open={glimpseArticleDialog.dialog}
      fullWidth
      maxWidth="sm"
      onClose={() => dispatch(handleGlimpseArticleDialog())}
    >
      <>
        <DialogTitle>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                display: "flex",
                color: "base.contrastText",
                flexGrow: 1,
              }}
            >
              <ManageSearchIcon />
              <Typography variant="body1">チラ見</Typography>
            </Box>
            <IconButton onClick={() => dispatch(handleGlimpseArticleDialog())}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          {isLoading ? (
            <LoadingCircular height={236} />
          ) : (
            <>
              <Box
                sx={{
                  textAlign: "center",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  px: { xs: 1, sm: 2, md: 8 },
                }}
              >
                <Typography variant="h7" color="primary">
                  {data.title}
                </Typography>
              </Box>
              <Divider sx={{ mx: { xs: 1, sm: 2, md: 8 } }} />
              <Box
                sx={{
                  fontSize: 12,
                  height: 188,
                  overflow: "hidden",
                  mt: 1,
                  pl: { xs: 1, sm: 4, md: 6 },
                }}
              >
                <TextEditor
                  readOnly
                  editorCore={editorCore}
                  defaultValue={data && JSON.parse(data.text)}
                />
              </Box>
            </>
          )}
        </DialogContent>
      </>
    </Dialog>
  );
});

// お知らせ確認Dialog_管理者Menu_公開状態切替Dialog
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { UpdateDbDialog } from "../Share/UpdateDbDialog";
import { useMutateNotificationApi } from "../../hooks/useMutateNotificationApi";
import {
  handleNotificationDialog,
  handlePatchIsActiveNotificationState,
} from "../../features/notificationStateSlice";

export const PatchIsActiveNotificationCheckDialog = memo((props) => {
  const { params, onChangeNotificationIsActive } = props;
  const dispatch = useDispatch();
  const { patchIsActiveNotificationState, notificationDialog } = useSelector(
    (state) => state.notificationState
  );
  const { patchIsActivedNotificationMutation } = useMutateNotificationApi();

  return (
    <UpdateDbDialog
      open={patchIsActiveNotificationState.dialog}
      onClickCloseDialog={onChangeNotificationIsActive}
      content={
        <Typography color="error">
          このお知らせの公開状態を変更します
        </Typography>
      }
      onClickUpdate={() => patchIsActivedNotificationMutation.mutate(params)}
      updating={patchIsActiveNotificationState.updating}
      updated={patchIsActiveNotificationState.updated}
      onClickCheckUpdated={() => {
        dispatch(
          handlePatchIsActiveNotificationState({
            dialog: false,
            updated: false,
          })
        );
        notificationDialog.dialog && dispatch(handleNotificationDialog());
      }}
    />
  );
});

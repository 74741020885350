// 自分の記事管理_自分の記事管理テーブル
import { memo } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";
import DetailsIcon from "@mui/icons-material/Details";
import CheckIcon from "@mui/icons-material/Check";
import BlockIcon from "@mui/icons-material/Block";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import CommentIcon from "@mui/icons-material/Comment";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import { handleMyArticleDialog } from "../../features/articleStateSlice";
import { CustomDataGrid } from "../Share/CustomDataGrid";
import { useQueryMyArticleListApi } from "../../hooks/useQueryMyArticleListApi";

export const MyPageManageMyArticleTable = memo(() => {
  const dispatch = useDispatch();
  const { index } = useParams();
  const { data, isLoading } = useQueryMyArticleListApi(index);

  const columns = [
    {
      field: "check",
      headerName: "確認",
      headerAlign: "center",
      maxWidth: 52,
      minWidth: 52,
      align: "center",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (row) => {
        return (
          <IconButton
            onClick={() => dispatch(handleMyArticleDialog(row.row.id))}
            disabled={isLoading}
          >
            <DetailsIcon />
          </IconButton>
        );
      },
    },
    { field: "id", width: 72, align: "right" },
    {
      field: "title",
      headerName: "タイトル",
      width: 382,
      sortable: false,
    },
    {
      field: "is_published",
      headerName: "公開",
      maxWidth: 52,
      minWidth: 52,
      type: "boolean",
      align: "center",
      disableColumnMenu: true,
      renderCell: (row) => {
        return row.row.is_banned ? (
          <Tooltip
            title="この記事は管理者により公開禁止にされています"
            placement="right"
          >
            <BlockIcon color="error" />
          </Tooltip>
        ) : (
          row.row.is_published && <CheckIcon color="secondary" />
        );
      },
    },
    {
      field: "like",
      renderHeader: () => (
        <ThumbUpAltIcon fontSize="small" sx={{ color: "base.contrastText" }} />
      ),
      maxWidth: 64,
      minWidth: 64,
      headerAlign: "center",
      align: "right",
      disableColumnMenu: true,
      sortable: false,
      renderCell: (row) => {
        return row.row.like.length;
      },
    },
    {
      field: "comments",
      renderHeader: () => (
        <CommentIcon fontSize="small" sx={{ color: "base.contrastText" }} />
      ),
      maxWidth: 64,
      minWidth: 64,
      headerAlign: "center",
      align: "right",
      disableColumnMenu: true,
      sortable: false,
      renderCell: (row) => {
        return row.row.comments.length;
      },
    },
    {
      field: "bookmark",
      renderHeader: () => (
        <StarOutlineIcon sx={{ color: "base.contrastText" }} />
      ),
      maxWidth: 64,
      minWidth: 64,
      headerAlign: "center",
      align: "right",
      disableColumnMenu: true,
      sortable: false,
      renderCell: (row) => {
        return row.row.followers.length;
      },
    },
    {
      field: "created_at",
      headerName: "作成日",
      width: 150,
    },
    {
      field: "initial_published_at",
      headerName: "投稿日",
      width: 150,
    },
    {
      field: "last_published_at",
      headerName: "更新日",
      width: 150,
    },
  ];

  return (
    <CustomDataGrid
      rows={isLoading || !data ? [] : data.results}
      columns={columns}
      loading={isLoading}
    />
  );
});

// Header_検索エリア
import { memo, useState, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { HeaderSearchAreaPopper } from "./HeaderSearchAreaPopper";
import { HeaderSearchAreaInputField } from "./HeaderSearchAreaInputField";

export const HeaderSearchArea = memo((props) => {
  const navigate = useNavigate();
  const { loading, tagItems, id } = props;
  const [articleSearchMenuPopper, setArticleSearchMenuPopper] = useState(false);
  const articleSearchMenuAnchorRef = useRef(null);
  const { articleSearchParams } = useSelector(
    (state) => state.articleSearchParams
  );

  // 検索設定Popper開閉
  const onFocusArticleSearchMenuPopperOpen = useCallback(() => {
    setArticleSearchMenuPopper(true);
  }, []);
  const onClickAwayArticleSearchMenuPopperClose = useCallback(
    (event) => {
      if (
        articleSearchMenuAnchorRef.current &&
        articleSearchMenuAnchorRef.current.contains(event.target)
      ) {
        return;
      }
      setArticleSearchMenuPopper(false);
    },
    [articleSearchMenuAnchorRef, articleSearchMenuPopper]
  );

  // 検索
  const onClickArticleSearch = useCallback(() => {
    navigate(
      `/article_search/results/index1?scope=${
        articleSearchParams.scope
      }&keyword=${articleSearchParams.keyword
        .trim()
        .replace(/\s+/g, ",")}&tags=${articleSearchParams.tags
        .map((x) => x.slug)
        .join(",")}&bookmarked=${articleSearchParams.bookmarked}&or=${
        articleSearchParams.or
      }&author=${articleSearchParams.myArticle ? id : null}`
    );
    setArticleSearchMenuPopper(false);
  }, [articleSearchParams]);

  // エンターキー押下時検索（検索エリア操作後に有効となる）
  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "Enter" && event.keyCode === 13) {
        onClickArticleSearch();
      }
    },
    [articleSearchParams]
  );

  return (
    <>
      <HeaderSearchAreaPopper
        articleSearchMenuPopper={articleSearchMenuPopper}
        articleSearchMenuAnchorRef={articleSearchMenuAnchorRef}
        onClickAwayArticleSearchMenuPopperClose={
          onClickAwayArticleSearchMenuPopperClose
        }
        tagItems={tagItems}
        onClickArticleSearch={onClickArticleSearch}
        handleKeyDown={handleKeyDown}
      />
      <HeaderSearchAreaInputField
        articleSearchMenuPopper={articleSearchMenuPopper}
        articleSearchMenuAnchorRef={articleSearchMenuAnchorRef}
        onFocusArticleSearchMenuPopperOpen={onFocusArticleSearchMenuPopperOpen}
        loading={loading}
        handleKeyDown={handleKeyDown}
      />
    </>
  );
});

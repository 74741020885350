// あなたへのお知らせ取得
import { useQuery } from "react-query";
import { client } from "../utils/authClient";

const getMessageList = async () => {
  const { data } = await client.get("/message/", {
    params: {
      page_size: 50,
    },
  });
  return data;
};

export const useQueryMessageListApi = () => {
  return useQuery({
    queryKey: ["message_list"],
    queryFn: getMessageList,
    cacheTime: 1000 * 60 * 3,
    staleTime: 0,
  });
};

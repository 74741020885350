// MyPage:あなたへのお知らせ_通知テンプレート
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Box, Typography, Button } from "@mui/material";
import { handleMessageDialog } from "../features/messageStateSlice";

// テンプレート
// type:commented
const CommentedTemplate = memo(({ content }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <>
      <Typography textAlign="center">{`あなたが投稿した記事「${content.article_title}」に、${content.commenter_name}さんからコメントが届いています。`}</Typography>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
        <Button
          size="large"
          variant="contained"
          onClick={() => {
            navigate(`/article_search/${content.article_id}`);
            dispatch(handleMessageDialog());
          }}
        >
          記事を開く
        </Button>
      </Box>
    </>
  );
});

// 通知テンプレート
export const messageTemplate = (item) => {
  const messageProps = {
    commented: {
      subject: "記事にコメントが付きました！",
      text: <CommentedTemplate content={item.content} />,
    },
  }[item.type];
  return messageProps;
};

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { MsalProvider } from "@azure/msal-react";
import { store } from "./features/store";
import { useQueryMeApi } from "./hooks/useQueryMeApi";
import { PageLayout } from "./PageLayout";
import { HomeLanding } from "./Containers/HomeLanding";
import { HomeNotification } from "./Containers/HomeNotification";
import { ArticleSearchResults } from "./Containers/ArticleSearchResults";
import { ArticleSearchTags } from "./Containers/ArticleSearchTags";
import { MyPageManageMyArticle } from "./Containers/MyPageManageMyArticle";
import { MyPageProfile } from "./Containers/MyPageProfile";
import { AdminManageNotification } from "./Containers/AdminManageNotification";
import { AdminManageArticle } from "./Containers/AdminManageArticle";
import { AdminAccessLog } from "./Containers/AdminAccessLog";
import { NotFound } from "./Containers/NotFound";
import { useQueryTagApi } from "./hooks/useQueryTag";
import { Article } from "./Containers/Article";
import { useQueryMessageListApi } from "./hooks/useQueryMessageListApi";
import { MyPageMessage } from "./Containers/MyPageMessage";
import { ArticleForAnotherTab } from "./Containers/ArticleForAnotherTab";
import { LoadingCircular } from "./Components/Share/LoadingCircular";

// ***Route:一般***
// ルーティングリスト
const routeContainers = [
  { path: "/home/notification/index:index", element: <HomeNotification /> },
  {
    path: "/article_search/results/index:index",
    element: <ArticleSearchResults />,
  },
  { path: "/article_search/:id", element: <Article /> },
  {
    path: "/article_search/all/index:index",
    element: <ArticleSearchResults />,
  },
  {
    path: "/article_search/bookmarked/index:index",
    element: <ArticleSearchResults />,
  },
  { path: "/article_search/tags", element: <ArticleSearchTags /> },
  { path: "/my_page/profile", element: <MyPageProfile /> },
  {
    path: "/my_page/my_article/index:index",
    element: <MyPageManageMyArticle />,
  },
  {
    path: "/my_page/message",
    element: <MyPageMessage />,
  },
  { path: "*", element: <NotFound /> },
];

// Route:me・tag・message未取得の場合はfecth画面へ遷移しget後にルーティング
const RouteCheckDefaultState = ({ element }) => {
  const me = useQueryMeApi();
  const tag = useQueryTagApi();
  const message = useQueryMessageListApi();
  const isChecked = me.data && tag.data && message.data;
  return isChecked ? <>{element}</> : <LoadingCircular />;
};

// 別タブ用:me・tag・message未取得の場合はfecth画面へ遷移しget後にルーティング
const RouteCheckDefaultStateForAnotherTab = ({ element }) => {
  const me = useQueryMeApi();
  const tag = useQueryTagApi();
  const message = useQueryMessageListApi();
  const isChecked = me.data && tag.data && message.data;
  return isChecked ? (
    <>{element}</>
  ) : (
    <div style={{ height: "100vh" }}>
      <LoadingCircular />
    </div>
  );
};

// ***Route:管理者のみ***
// ルーティングリスト
const routeStaffOnlyContainers = [
  { path: "/admin/article/index:index", element: <AdminManageArticle /> },
  {
    path: "/admin/notification/index:index",
    element: <AdminManageNotification />,
  },
  { path: "/admin/access_log/index:index", element: <AdminAccessLog /> },
];

// Route:me・tag・message未取得の場合はfecth画面へ遷移しget後にルーティング&管理者以外のページ遷移防止
const RouteStaffOnly = ({ element }) => {
  const me = useQueryMeApi();
  const tag = useQueryTagApi();
  const message = useQueryMessageListApi();
  const isChecked = me.data && tag.data && message.data;

  return !isChecked ? (
    <LoadingCircular />
  ) : isChecked && me.data.is_staff ? (
    <>{element}</>
  ) : (
    <NotFound />
  );
};

// ***ReactQueryconfig***
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

export const AuthRoutes = ({ instance }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <Router>
          <MsalProvider instance={instance}>
            <Routes>
              <Route
                path="/article_search/for_another_tag/:id"
                element={
                  <RouteCheckDefaultStateForAnotherTab
                    element={<ArticleForAnotherTab />}
                  />
                }
              />
              <Route path="/" element={<PageLayout />}>
                <Route
                  index
                  element={<RouteCheckDefaultState element={<HomeLanding />} />}
                />
                {routeContainers.map((item, index) => {
                  return (
                    <Route
                      key={index}
                      path={item.path}
                      element={
                        <RouteCheckDefaultState element={item.element} />
                      }
                    />
                  );
                })}
                {routeStaffOnlyContainers.map((item, index) => {
                  return (
                    <Route
                      key={index}
                      path={item.path}
                      element={<RouteStaffOnly element={item.element} />}
                    />
                  );
                })}
              </Route>
            </Routes>
          </MsalProvider>
        </Router>
      </Provider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
};

// 404NotFound
import { Grid, Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";

export const NotFound = () => {
  const navigate = useNavigate();
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ height: "100%" }}
      spacing={2}
    >
      <Grid item sx={{ display: "flex" }}>
        <SentimentVeryDissatisfiedIcon />
        <Typography sx={{ ml: 1 }}>404 Not Found</Typography>
      </Grid>
      <Grid item sx={{ textAlign: "center" }}>
        <Typography color="error" variant="h5">
          お探しのページは見つかりません
        </Typography>
        <Typography sx={{ mb: 2 }}>
          URLは直接入力せず、メニューの各リンクをご利用下さい
        </Typography>
      </Grid>
      <Grid item>
        <Button onClick={() => navigate("/")}>Homeへ戻る</Button>
      </Grid>
    </Grid>
  );
};

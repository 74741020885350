// 管理者用)記事管理_記事確認
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ArticleDialog } from "../ArticleDialog/ArticleDialog";
import { handleAdminArticleDialog } from "../../features/articleStateSlice";
import { useQueryAdmArticleApi } from "../../hooks/useQueryAdmArticleApi";

export const AdminArticleDialog = memo(() => {
  const dispatch = useDispatch();
  const { adminArticleDialog } = useSelector((state) => state.articleState);
  const { data } = useQueryAdmArticleApi(adminArticleDialog.id);

  return (
    <ArticleDialog
      open={adminArticleDialog.dialog}
      onClickClose={() => dispatch(handleAdminArticleDialog())}
      data={data}
      isAdmin
    />
  );
});

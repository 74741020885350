// 記事のいいね・ブックマーク・コメント更新Mutate
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQueryClient } from "react-query";
import { client } from "../utils/authClient";
import { handleArticleCommentUpdating } from "../features/articleStateSlice";

export const useMutateArticleOptionsApi = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const {
    articleCreateComment,
    articleCreateReplyCommentParams,
    articleEditDeleteCommentParams,
  } = useSelector((state) => state.articleState);

  //  いいねToggle（post)
  const putArticleLikeMutation = useMutation(
    (id) => client.post(`/article/${id}/like/`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["article"]);
        queryClient.invalidateQueries(["article_list"]);
        queryClient.invalidateQueries(["my_article"]);
        queryClient.invalidateQueries(["my_article_list"]);
        queryClient.invalidateQueries(["adm_article"]);
        queryClient.invalidateQueries(["adm_article_list"]);
      },
    }
  );

  //  ***ブックマーク***
  // 登録（put）
  const putArticleBookmarkMutation = useMutation(
    (params) => client.put(`article/${params.id}/bookmark/${params.me_id}/`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["article"]);
        queryClient.invalidateQueries(["article_list"]);
        queryClient.invalidateQueries(["my_article"]);
        queryClient.invalidateQueries(["my_article_list"]);
        queryClient.invalidateQueries(["adm_article"]);
        queryClient.invalidateQueries(["adm_article_list"]);
      },
    }
  );

  //  削除
  const deleteArticleBookmarkMutation = useMutation(
    (params) => client.delete(`article/${params.id}/bookmark/${params.me_id}/`),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["article"]);
        queryClient.invalidateQueries(["article_list"]);
        queryClient.invalidateQueries(["my_article"]);
        queryClient.invalidateQueries(["my_article_list"]);
        queryClient.invalidateQueries(["adm_article"]);
        queryClient.invalidateQueries(["adm_article_list"]);
      },
    }
  );

  //  ***コメント***
  // Post
  const postArticleCommentMutation = useMutation(
    (id) =>
      client.post(`article/${id}/comment/`, {
        ancestor: null,
        text: articleCreateComment,
      }),
    {
      onMutate: () => dispatch(handleArticleCommentUpdating(true)),
      onSuccess: () => {
        dispatch(handleArticleCommentUpdating(false));
        queryClient.invalidateQueries(["article_comment"]);
        queryClient.invalidateQueries(["article_list"]);
        queryClient.invalidateQueries(["my_article_list"]);
        queryClient.invalidateQueries(["adm_article_list"]);
      },
    }
  );

  // 返信Post
  const postArticleReplyCommentMutation = useMutation(
    (id) =>
      client.post(`article/${id}/comment/`, articleCreateReplyCommentParams),
    {
      onMutate: () => dispatch(handleArticleCommentUpdating(true)),
      onSuccess: () => {
        dispatch(handleArticleCommentUpdating(false));
        queryClient.invalidateQueries(["article_comment"]);
        queryClient.invalidateQueries(["article_list"]);
        queryClient.invalidateQueries(["my_article_list"]);
        queryClient.invalidateQueries(["adm_article_list"]);
      },
    }
  );

  // Patch
  const patchArticleCommentMutation = useMutation(
    (id) =>
      client.patch(
        `article/${id}/comment/${articleEditDeleteCommentParams.id}/`,
        { text: articleEditDeleteCommentParams.text }
      ),
    {
      onMutate: () => dispatch(handleArticleCommentUpdating(true)),
      onSuccess: () => {
        dispatch(handleArticleCommentUpdating(false));
        queryClient.invalidateQueries(["article_comment"]);
        queryClient.invalidateQueries(["article_list"]);
        queryClient.invalidateQueries(["my_article_list"]);
        queryClient.invalidateQueries(["adm_article_list"]);
      },
    }
  );

  // delete
  const deleteArticleCommentMutation = useMutation(
    (id) =>
      client.delete(
        `article/${id}/comment/${articleEditDeleteCommentParams.id}/`
      ),
    {
      onMutate: () => dispatch(handleArticleCommentUpdating(true)),
      onSuccess: () => {
        dispatch(handleArticleCommentUpdating(false));
        queryClient.invalidateQueries(["article_comment"]);
        queryClient.invalidateQueries(["article_list"]);
        queryClient.invalidateQueries(["my_article_list"]);
        queryClient.invalidateQueries(["adm_article_list"]);
      },
    }
  );

  return {
    putArticleLikeMutation,
    putArticleBookmarkMutation,
    deleteArticleBookmarkMutation,
    postArticleCommentMutation,
    postArticleReplyCommentMutation,
    patchArticleCommentMutation,
    deleteArticleCommentMutation,
  };
};

// 複数コンポーネントで利用する共通関数・変数
// 重複タグ削除:英小文字統一・空白詰めしタグ名重複削除後に最初の文字を英大文字にする（日本語は対象外）
export const shapeTags = (array) => {
  const aligncase = array.map((item) => {
    return {
      ...item,
      name: item.name.toLowerCase().replaceAll(/\s+/g, ""),
    };
  });
  const uniqueName = Array.from(
    new Map(aligncase.map((item) => [item.name, item])).values()
  );
  const uppercaseFirst = uniqueName.map((item) => {
    return {
      ...item,
      name: item.name.charAt(0).toUpperCase() + item.name.slice(1),
    };
  });
  return uppercaseFirst;
};

// SideMenu/main・sub共通/pathnameのアクティブパラメータの2番目のスラッシュまでを取得
export const firstPathname = (pathname) => {
  const result = pathname.split("/");
  if (result[1] != "") {
    return result[1];
  } else {
    return "home";
  }
};

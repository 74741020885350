// 記事作成・編集共通_公開状態・タイトル・Tab編集orプレビュー
import { memo, useState } from "react";
import { useQueryTagApi } from "../../hooks/useQueryTag";
import { CreateEditArticleDialogPreviewTitleArea } from "./CreateEditArticleDialogPreviewTitleArea";
import { CreateEditArticleDialogTitleArea } from "./CreateEditArticleDialogTitleArea";

export const CreateEditArticleDialogSwitchInputPreview = memo((props) => {
  const { isPost, defaultTags, previewTab } = props;
  const { data, isLoading } = useQueryTagApi();
  const [tagList, setTagList] = useState(data.map((x) => x.name));

  if (isLoading) return;

  return (
    <>
      {previewTab ? (
        <CreateEditArticleDialogPreviewTitleArea />
      ) : (
        <CreateEditArticleDialogTitleArea
          tagList={tagList}
          setTagList={setTagList}
          defaultTags={defaultTags}
          isPost={isPost}
        />
      )}
    </>
  );
});

// あなたへのお知らせ関連State
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  messageDialog: { id: "", dialog: false },
};

const messageStateSlice = createSlice({
  name: "message",
  initialState,
  reducers: {
    handleMessageDialog: (state, actions) => {
      if (!state.messageDialog.dialog) {
        state.messageDialog = { id: actions.payload, dialog: true };
      } else {
        state.messageDialog = {
          ...state.messageDialog,
          dialog: false,
        };
      }
    },
  },
});

export const { handleMessageDialog } = messageStateSlice.actions;

export default messageStateSlice.reducer;

// 記事閲覧_作者・作成日・タイトル・その他情報エリア_ブックマーク操作
import { memo, useCallback } from "react";
import { Box, Tooltip, Zoom } from "@mui/material";
import { useQueryMeApi } from "../../hooks/useQueryMeApi";
import { useMutateArticleOptionsApi } from "../../hooks/useMutateArticleOptionsApi";
import { useEffect, useRef } from "react";
import Lottie from "lottie-react";
import BookmarkAnimation from "../../Images/BookmarkAnimation.json";

export const ArticleBookmarkState = memo(({ data }) => {
  const lottieRef = useRef(null);
  const me = useQueryMeApi();
  const isBookmarked = data.followers.includes(me.data.id);
  const { putArticleBookmarkMutation, deleteArticleBookmarkMutation } =
    useMutateArticleOptionsApi();

  const isLoading =
    putArticleBookmarkMutation.isLoading ||
    deleteArticleBookmarkMutation.isLoading;

  // 初期状態(静止)
  useEffect(() => {
    if (isBookmarked) {
      lottieRef.current.playSegments([[43, 44]], true);
    } else {
      lottieRef.current.playSegments([[4, 5]], true);
    }
  }, []);

  // 更新時
  const handleBookmark = useCallback(() => {
    if (isBookmarked) {
      lottieRef.current.playSegments([[36, 8]], true);
      deleteArticleBookmarkMutation.mutate({
        id: data.id,
        me_id: me.data.id,
      });
    } else {
      lottieRef.current.playSegments([[6, 46]], true);
      putArticleBookmarkMutation.mutate({
        id: data.id,
        me_id: me.data.id,
      });
    }
  }, [isBookmarked, lottieRef]);

  return (
    <Box>
      <Tooltip
        title={isBookmarked ? "ブックマークを削除" : "この記事をブックマーク"}
        TransitionComponent={Zoom}
      >
        <span>
          <Lottie
            lottieRef={lottieRef}
            animationData={BookmarkAnimation}
            loop={false}
            autoplay={false}
            style={{
              width: 50,
              height: 50,
              cursor: !isLoading && "pointer",
              pointerEvents: isLoading && "none",
              opacity: isLoading && 0.2,
            }}
            onClick={handleBookmark}
          />
        </span>
      </Tooltip>
    </Box>
  );
});

// 記事検索条件管理
import { createSlice } from "@reduxjs/toolkit";

const defaultParamsState = {
  author: "",
  tags: [],
  keyword: "",
  scope: "fulltext",
  bookmarked: false,
  myArticle: false,
  or: "OR",
};

const initialState = {
  articleSearchParams: defaultParamsState,
};

const articleSearchParamsSlice = createSlice({
  name: "articleSearchParams",
  initialState,
  reducers: {
    onChangeArticleSearchParams: (state, actions) => {
      state.articleSearchParams = {
        ...state.articleSearchParams,
        ...actions.payload,
      };
    },
    onClickClearArticleSearchParams: (state) => {
      state.articleSearchParams = defaultParamsState;
    },
  },
});

export const { onChangeArticleSearchParams, onClickClearArticleSearchParams } =
  articleSearchParamsSlice.actions;

export default articleSearchParamsSlice.reducer;

// 記事閲覧_コメント欄操作_コメントリスト
import { memo } from "react";
import { useDispatch } from "react-redux";
import {
  List,
  Divider,
  Typography,
  Box,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useQueryArticleCommentApi } from "../../hooks/useQueryArticleCommentApi";
import { handleCreateReplyArticleCommentDialog } from "../../features/articleStateSlice";
import { ArticleCommentsListItem } from "./ArticleCommentsListItem";

const filterChildCommets = (array, item) =>
  array.filter((x) => x.ancestor == item.id);

export const ArticleCommentsList = memo(({ id }) => {
  const dispatch = useDispatch();
  const { data, isLoading } = useQueryArticleCommentApi(id);

  const parentCommentsItems =
    !isLoading &&
    data.filter((item) => {
      return !item.ancestor;
    });
  const childCommentsItems =
    !isLoading &&
    data.filter((item) => {
      return item.ancestor;
    });

  if (isLoading) return;

  return (
    <List sx={{ width: "100%" }}>
      {parentCommentsItems.map((item, index) => {
        return (
          <Box key={index}>
            <ArticleCommentsListItem item={item} />
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Chip
                color="primary"
                label="このコメントに返信"
                size="small"
                sx={{ fontSize: 12, cursor: "pointer", mr: 2 }}
                onClick={() =>
                  dispatch(
                    handleCreateReplyArticleCommentDialog({
                      ancestor: item.id,
                      text: "",
                    })
                  )
                }
              />
            </Box>
            {Boolean(filterChildCommets(childCommentsItems, item).length) && (
              <Accordion
                disableGutters
                variant="outlined"
                square
                sx={{
                  border: "none",
                  "&.MuiAccordion-root:before": {
                    bgcolor: "base.main",
                  },
                }}
              >
                <AccordionSummary
                  expandIcon={
                    <ArrowDropDownIcon color="primary" sx={{ mb: 0.2 }} />
                  }
                  sx={{
                    minHeight: 0,
                    maxHeight: 18,
                    mt: 1,
                    "& .MuiAccordionSummary-content": {
                      justifyContent: "flex-end",
                      p: 0,
                    },
                  }}
                >
                  <Typography variant="body2" color="primary">
                    {`${
                      filterChildCommets(childCommentsItems, item).length
                    }件の返信コメント`}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ py: 0, pl: 4.8 }}>
                  <List sx={{ width: "100%" }} disablePadding>
                    {filterChildCommets(childCommentsItems, item).map(
                      (item, index) => {
                        return (
                          <ArticleCommentsListItem
                            key={index}
                            item={item}
                            isReply
                          />
                        );
                      }
                    )}
                  </List>
                </AccordionDetails>
              </Accordion>
            )}
            <Divider variant="inset" component="li" sx={{ mt: 1 }} />
          </Box>
        );
      })}
    </List>
  );
});

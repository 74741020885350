// Home:ランディング
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Box, Typography, Button, Divider } from "@mui/material";
import { ContentCard } from "../Components/Share/ContentCard";
import FeedIcon from "@mui/icons-material/Feed";
import { useQuerySearchArticleApi } from "../hooks/useQuerySearchArticleApi";
import { ArticleSearchList } from "../Components/ArticleSearch/ArticleSearchList";
import SampleImage from "../Images/knowledge_campus_banner.png";
import { useQueryNotificationListApi } from "../hooks/useQueryNotificationListApi";
import { HomeNotificationList } from "../Components/Home/HomeNotificationList";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import { HomeNotificationDialog } from "../Components/Home/HomeNotificationDialog";
import { CreateNotificationDialog } from "../Components/NotificationDialog/CreateNotificationDialog";
import { PostNotificationCheckDialog } from "../Components/NotificationDialog/PostNotificationCheckDialog";
import { EditNotificationDialog } from "../Components/NotificationDialog/EditNotificationDialog";
import { PutNotificationCheckDialog } from "../Components/NotificationDialog/PutNotificationCheckDialog";
import { useQueryMeApi } from "../hooks/useQueryMeApi";
import { onClickCreateNotificationDialogOpen } from "../features/notificationStateSlice";
import { ScrollBox } from "../Components/Share/ScrollBox";

export const HomeLanding = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const article = useQuerySearchArticleApi({
    page: 1,
    page_size: 10,
  });
  const notification = useQueryNotificationListApi(1);
  const me = useQueryMeApi();

  return (
    <>
      <HomeNotificationDialog />
      <CreateNotificationDialog />
      <PostNotificationCheckDialog />
      <EditNotificationDialog />
      <PutNotificationCheckDialog />
      <Box sx={{ display: "flex", height: "100%" }}>
        <ContentCard sx={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              py: 4,
              bgcolor: "primary.light",
            }}
          >
            <img src={SampleImage} width="100%" />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: "base.contrastText",
              pl: 4,
              mt: 4,
            }}
          >
            <AnnouncementIcon />
            <Typography variant="h7" sx={{ ml: 1, flexGrow: 1 }}>
              お知らせ
            </Typography>
            {me.data.is_staff && (
              <Button
                onClick={() => dispatch(onClickCreateNotificationDialogOpen())}
                sx={{ maxHeight: 0, ml: 4 }}
              >
                新規作成
              </Button>
            )}
          </Box>
          <Divider sx={{ mt: 1, mx: 4 }} />
          <ScrollBox sx={{ px: 2 }}>
            <HomeNotificationList
              data={notification.data}
              isLoading={notification.isLoading}
            />
          </ScrollBox>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 2,
              pl: 2,
              height: 32,
            }}
          >
            <Button onClick={() => navigate("/home/notification/index1")}>
              全てのお知らせを見る
            </Button>
          </Box>
        </ContentCard>
        <ContentCard
          sx={{
            maxWidth: 368,
            minWidth: 368,
            ml: 1,
            display: { xs: "none", xl: "flex" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              color: "base.contrastText",
              mb: 1,
            }}
          >
            <FeedIcon />
            <Typography variant="h7" sx={{ ml: 1 }}>
              最近更新された記事
            </Typography>
          </Box>
          <Divider />
          <ScrollBox>
            <ArticleSearchList
              articleList={
                !article.isLoading && article.data ? article.data.results : []
              }
              loading={article.isLoading}
              isHome
            />
          </ScrollBox>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: 2,
              pl: 2,
              height: 32,
            }}
          >
            <Button onClick={() => navigate("/article_search/all/index1")}>
              もっと見る
            </Button>
          </Box>
        </ContentCard>
      </Box>
    </>
  );
};

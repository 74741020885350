// 記事作成・編集共通_公開状態・タイトル・Tab編集エリア
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  TextField,
  Typography,
  FormControlLabel,
  Switch,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { onChangeArticleParams } from "../../features/articleStateSlice";
import { CreateEditArticleDialogTagCloud } from "./CreateEditArticleDialogTagCloud";

export const CreateEditArticleDialogTitleArea = memo((props) => {
  const { tagList, setTagList, defaultTags, isPost } = props;
  const dispatch = useDispatch();
  const { articleParams } = useSelector((state) => state.articleState);

  // タイトル文字数制限
  const capsLimit = articleParams.title.length > 180;

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
        <Box autoComplete="off" sx={{ flexGrow: 1 }}>
          <TextField
            fullWidth
            label="記事のタイトル*"
            value={articleParams.title}
            onChange={(event) =>
              dispatch(onChangeArticleParams({ title: event.target.value }))
            }
            error={capsLimit}
            helperText={capsLimit && "入力可能な文字数を超えています"}
          />
        </Box>

        <FormControlLabel
          sx={{ ml: { xs: 0, sm: 0, md: 2 } }}
          control={
            <Switch
              checked={articleParams.is_published}
              onChange={(event) =>
                dispatch(
                  onChangeArticleParams({
                    is_published: event.target.checked,
                  })
                )
              }
            />
          }
          label={
            <Typography>
              {articleParams.is_published ? "公開" : "非公開"}
            </Typography>
          }
        />
      </Box>
      <Accordion
        disableGutters
        variant="outlined"
        square
        defaultExpanded={isPost}
        sx={{
          mt: 1.6,
          border: "none",
          borderRadius: "4px",
          "&.MuiAccordion-root:before": {
            bgcolor: "#fff",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ minHeight: 0, maxHeight: 38 }}
        >
          <Typography variant="body2" color="primary">
            Tag選択*
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ py: 0 }}>
          <CreateEditArticleDialogTagCloud
            tagList={tagList}
            setTagList={setTagList}
            defaultTags={!isPost && defaultTags}
            isPost={isPost}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
});

// 記事関連State
import { createSlice } from "@reduxjs/toolkit";
import { now } from "../utils/datetime";

const defaultParamsState = {
  id: "",
  title: "",
  tags: [],
  owner: {
    display_name: "",
    email: "",
    user_avatar: null,
  },
  created_at: "",
  is_published: true,
  initial_published_at: "",
  last_published_at: "",
  like: [],
  comments: [],
};

const defaultFetchState = {
  dialog: false,
  updating: false,
  updated: false,
};

const initialState = {
  myArticleDialog: { id: "", dialog: false },
  adminArticleDialog: { id: "", dialog: false },
  glimpseArticleDialog: { id: "", dialog: false },
  createArticleDialog: false,
  editArticleDialog: false,
  articleParams: defaultParamsState,
  postArticleState: defaultFetchState,
  putArticleState: defaultFetchState,
  patchIsPublishedArticleState: defaultFetchState,
  patchAdminBannedArticleState: defaultFetchState,
  deleteArticleState: defaultFetchState,
  createArticlePreviewTab: 0,
  editArticlePreviewTab: 0,
  createReplyCommentDialog: false,
  editArticleCommentDialog: false,
  deleteArticleCommentCheckDialog: false,
  articleCreateComment: "",
  articleCreateReplyCommentParams: { ancestor: null, text: "" },
  articleEditDeleteCommentParams: { id: "", ancestor: null, text: "" },
  articleCommentUpdating: false,
  previousURL: null,
  createEdtiArticleTagList: [],
};

const articleStateSlice = createSlice({
  name: "articleState",
  initialState,
  reducers: {
    // ***onChange***
    // 記事作成・編集
    onChangeArticleParams: (state, actions) => {
      state.articleParams = {
        ...state.articleParams,
        ...actions.payload,
      };
    },
    // 新規作成・編集時タグリスト
    onChangeCreateEditArticleTagList: (state, actions) => {
      state.createEdtiArticleTagList = [
        ...state.createEdtiArticleTagList,
        ...actions.payload,
      ];
    },
    // *****Dialog開閉*****
    // 新規投稿作成開く
    onClickCreateArticleDialogOpen: (state, actions) => {
      state.articleParams = {
        ...defaultParamsState,
        initial_published_at: now,
        last_published_at: now,
      };
      state.createArticleDialog = true;
    },
    // コピー新規開く
    onClickCopyCreateArticleDialogOpen: (state, actions) => {
      state.articleParams = {
        ...actions.payload,
        initial_published_at: now,
        last_published_at: now,
      };
      state.createArticleDialog = true;
    },
    //　新規投稿閉じる
    onClickCreateArticleDialogClose: (state) => {
      state.createArticleDialog = false;
      state.createArticlePreviewTab = 0;
    },
    // 編集
    handleEditArticleDialog: (state, actions) => {
      if (!state.editArticleDialog) {
        state.articleParams = actions.payload;
      } else {
        state.editArticlePreviewTab = 0;
      }
      state.editArticleDialog = !state.editArticleDialog;
    },
    //  公開・非公開切替Patch
    handlePatchIsPublishedArticleDialog: (state) => {
      state.patchIsPublishedArticleState = {
        ...state.patchIsPublishedArticleState,
        dialog: !state.patchIsPublishedArticleState.dialog,
      };
    },
    //  My_Page記事確認Dialog開閉
    handleMyArticleDialog: (state, actions) => {
      if (!state.myArticleDialog.dialog) {
        state.myArticleDialog = { id: actions.payload, dialog: true };
      } else {
        state.myArticleDialog = { ...state.myArticleDialog, dialog: false };
      }
    },
    //  管理者Page記事確認Dialog開閉
    handleAdminArticleDialog: (state, actions) => {
      if (!state.adminArticleDialog.dialog) {
        state.adminArticleDialog = { id: actions.payload, dialog: true };
      } else {
        state.adminArticleDialog = {
          ...state.adminArticleDialog,
          dialog: false,
        };
      }
    },
    //  記事チラ見Dialog開閉
    handleGlimpseArticleDialog: (state, actions) => {
      if (!state.glimpseArticleDialog.dialog) {
        state.glimpseArticleDialog = { id: actions.payload, dialog: true };
      } else {
        state.glimpseArticleDialog = {
          ...state.glimpseArticleDialog,
          dialog: false,
        };
      }
    },
    // *****API更新処理関連*****
    //  Post
    handlePostArticleState: (state, actions) => {
      state.postArticleState = {
        ...state.postArticleState,
        ...actions.payload,
      };
    },
    //  Put
    handlePutArticleState: (state, actions) => {
      state.putArticleState = { ...state.putArticleState, ...actions.payload };
    },
    //  公開・非公開切替Patch
    handlePatchIsPublishedArticleState: (state, actions) => {
      state.patchIsPublishedArticleState = {
        ...state.patchIsPublishedArticleState,
        ...actions.payload,
      };
    },
    // 公開禁止・許可Patch（管理者）
    handlePatchAdminBannedArticleState: (state, actions) => {
      state.patchAdminBannedArticleState = {
        ...state.patchAdminBannedArticleState,
        ...actions.payload,
      };
    },
    // Delete
    handleDeleteArticleState: (state, actions) => {
      state.deleteArticleState = {
        ...state.deleteArticleState,
        ...actions.payload,
      };
    },
    // *****その他*****
    // Create/Preview切替Tab操作
    handleCreateArticlePreviewTab: (state, actions) => {
      state.createArticlePreviewTab = actions.payload;
    },
    // Edit/Preview切替Tab操作
    handleEditArticlePreviewTab: (state, actions) => {
      state.editArticlePreviewTab = actions.payload;
    },
    // 新規投稿・編集完了時の編集内容クリア
    onClickClearArticleParams: (state) => {
      state.articleParams = defaultParamsState;
    },
    //  記事閲覧ページ遷移時、1つ前のURLを格納する
    onClickSavePreviousURL: (state, actions) => {
      state.previousURL = actions.payload;
    },
    // *****Option*****
    // ***コメント***
    // 更新中/完了ステート変更
    handleArticleCommentUpdating: (state, actions) => {
      state.articleCommentUpdating = actions.payload;
    },
    // 返信用Dialog開閉
    handleCreateReplyArticleCommentDialog: (state, actions) => {
      if (!state.createReplyCommentDialog) {
        state.articleCreateReplyCommentParams = actions.payload;
      }
      state.createReplyCommentDialog = !state.createReplyCommentDialog;
    },
    // 編集用Dialog開閉
    handleEditArticleCommentDialog: (state, actions) => {
      if (!state.editArticleCommentDialog) {
        state.articleEditDeleteCommentParams = actions.payload;
      }
      state.editArticleCommentDialog = !state.editArticleCommentDialog;
    },
    // 削除確認Dialog開閉
    handleDeleteArticleCommentCheckDialog: (state, actions) => {
      if (!state.deleteArticleCommentCheckDialog) {
        state.articleEditDeleteCommentParams = actions.payload;
      }
      state.deleteArticleCommentCheckDialog =
        !state.deleteArticleCommentCheckDialog;
    },
    // 新規コメント作成
    onChangeArticleComment: (state, actions) => {
      state.articleCreateComment = actions.payload;
    },
    // 返信コメント作成
    onChangeArticleReplyComment: (state, actions) => {
      state.articleCreateReplyCommentParams = {
        ...state.articleCreateReplyCommentParams,
        text: actions.payload,
      };
    },
    // コメント編集
    onChangeEditArticleComment: (state, actions) => {
      state.articleEditDeleteCommentParams = {
        ...state.articleEditDeleteCommentParams,
        text: actions.payload,
      };
    },
  },
});

export const {
  onChangeArticleParams,
  handleMyArticleDialog,
  handleAdminArticleDialog,
  handleGlimpseArticleDialog,
  onClickCreateArticleDialogOpen,
  onClickCopyCreateArticleDialogOpen,
  onClickCreateArticleDialogClose,
  handleEditArticleDialog,
  handlePatchIsPublishedArticleDialog,
  handlePostArticleState,
  handlePutArticleState,
  handlePatchIsPublishedArticleState,
  handlePatchAdminBannedArticleState,
  handleDeleteArticleState,
  handleCreateArticlePreviewTab,
  handleEditArticlePreviewTab,
  onClickClearArticleParams,
  handleCreateReplyArticleCommentDialog,
  handleEditArticleCommentDialog,
  handleDeleteArticleCommentCheckDialog,
  onChangeArticleComment,
  onChangeArticleReplyComment,
  onChangeEditArticleComment,
  handleArticleCommentUpdating,
  onClickSavePreviousURL,
  onChangeCreateEditArticleTagList,
} = articleStateSlice.actions;

export default articleStateSlice.reducer;

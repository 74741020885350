// お知らせ作成・編集Dialog_開始終了日・公開状態・タイトル編集
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid, TextField, FormControlLabel, Switch } from "@mui/material";
import { CreateEditNotificationDialogDatePicker } from "./CreateEditNotificationDialogDatePicker";
import { onChangeNotificationParams } from "../../features/notificationStateSlice";
import { dateFormatter } from "../../utils/datetime";

export const CreateEditNotificationDialogTitleArea = memo(({ isPost }) => {
  const dispatch = useDispatch();
  const { notificationParams } = useSelector(
    (state) => state.notificationState
  );

  // タイトル文字数制限
  const capsLimit = notificationParams.title.length > 50;

  return (
    <>
      <Grid container sx={{ alignItems: "center" }}>
        <Grid item xs={12} sm={9.4} md={9} lg={6}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CreateEditNotificationDialogDatePicker
              label="周知開始日*"
              value={notificationParams.start_date}
              onChange={(value) =>
                dispatch(
                  onChangeNotificationParams({
                    start_date: dateFormatter(value),
                  })
                )
              }
              disabled={!isPost}
              disablePast={isPost}
            />
            <Box sx={{ mx: 1, display: { xs: "none", sm: "block" } }}>〜</Box>
            <CreateEditNotificationDialogDatePicker
              label="周知終了日"
              value={notificationParams.end_date}
              onChange={(value) =>
                dispatch(
                  onChangeNotificationParams({
                    end_date: dateFormatter(value),
                  })
                )
              }
              minDate={notificationParams.start_date}
              isEndDate
              onClickClear={() =>
                dispatch(onChangeNotificationParams({ end_date: null }))
              }
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={2.6}>
          <FormControlLabel
            sx={{ ml: { xs: 0, sm: 1 } }}
            control={
              <Switch
                checked={notificationParams.is_active}
                onChange={(event) =>
                  dispatch(
                    onChangeNotificationParams({
                      is_active: event.target.checked,
                    })
                  )
                }
              />
            }
            label={notificationParams.is_active ? "公開" : "非公開"}
          />
        </Grid>
      </Grid>
      <TextField
        fullWidth
        label="タイトル*"
        margin="normal"
        value={notificationParams.title}
        onChange={(event) =>
          dispatch(onChangeNotificationParams({ title: event.target.value }))
        }
        autoFocus
        error={capsLimit}
        helperText={capsLimit && "入力可能な文字数を超えています"}
      />
    </>
  );
});

// 記事閲覧_自分の記事Menu_記事編集Dialog_更新確認Dialog
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Typography } from "@mui/material";
import { UpdateDbDialog } from "../Share/UpdateDbDialog";
import {
  handleEditArticleDialog,
  handleMyArticleDialog,
  handlePutArticleState,
  onClickClearArticleParams,
} from "../../features/articleStateSlice";
import { useMutateArticleApi } from "../../hooks/useMutateArticleApi";

export const PutArticleCheckDialog = memo(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const { articleParams, putArticleState, previousURL } = useSelector(
    (state) => state.articleState
  );
  const { putArticleMutation } = useMutateArticleApi();

  return (
    <UpdateDbDialog
      open={putArticleState.dialog}
      onClickCloseDialog={() =>
        dispatch(handlePutArticleState({ dialog: false }))
      }
      content={<Typography color="error">この内容で更新します</Typography>}
      onClickUpdate={() => putArticleMutation.mutate()}
      updating={putArticleState.updating}
      updated={putArticleState.updated}
      onClickCheckUpdated={() => {
        dispatch(onClickClearArticleParams());
        dispatch(handlePutArticleState({ dialog: false, updated: false }));
        dispatch(handleEditArticleDialog());
        if (pathname == `/article_search/${articleParams.id}`) {
          previousURL
            ? navigate(previousURL)
            : navigate("/my_page/my_article/index1");
        } else {
          dispatch(handleMyArticleDialog());
        }
      }}
    />
  );
});

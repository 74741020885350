// お知らせ更新Mutate
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQueryClient } from "react-query";
import { client } from "../utils/authClient";
import {
  handleDeleteNotificationState,
  handlePatchIsActiveNotificationState,
  handlePostNotificationState,
  handlePutNotificationState,
} from "../features/notificationStateSlice";

export const useMutateNotificationApi = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { notificationParams } = useSelector(
    (state) => state.notificationState
  );

  //  Post
  const postNotificaitonMutation = useMutation(
    () =>
      client.post("/adm_notification/", {
        title: notificationParams.title,
        text: notificationParams.text,
        start_date: `${notificationParams.start_date}T00:00`,
        end_date: notificationParams.end_date
          ? notificationParams.end_date + "T00:00"
          : null,
        is_active: notificationParams.is_active,
      }),
    {
      onMutate: () => {
        dispatch(handlePostNotificationState({ updating: true }));
      },
      onSuccess: () => {
        dispatch(
          handlePostNotificationState({ updating: false, updated: true })
        );
        queryClient.invalidateQueries(["notification_list"]);
        queryClient.invalidateQueries(["adm_notification_list"]);
      },
    }
  );
  //  Put
  const putNotificationMutation = useMutation(
    () =>
      client.put(`/adm_notification/${notificationParams.id}/`, {
        title: notificationParams.title,
        text: notificationParams.text,
        start_date: notificationParams.start_date + "T00:00",
        end_date: notificationParams.end_date
          ? notificationParams.end_date + "T00:00"
          : null,
        is_active: notificationParams.is_active,
      }),
    {
      onMutate: () => {
        dispatch(handlePutNotificationState({ updating: true }));
      },
      onSuccess: () => {
        dispatch(
          handlePutNotificationState({ updating: false, updated: true })
        );
        queryClient.invalidateQueries(["notification"]);
        queryClient.invalidateQueries(["adm_notification"]);
        queryClient.invalidateQueries(["notification_list"]);
        queryClient.invalidateQueries(["adm_notification_list"]);
      },
    }
  );
  //  公開・非公開切替Patch
  const patchIsActivedNotificationMutation = useMutation(
    (params) =>
      client.patch(`/adm_notification/${params.id}/`, {
        is_active: params.is_active,
      }),
    {
      onMutate: () => {
        dispatch(handlePatchIsActiveNotificationState({ updating: true }));
      },
      onSuccess: () => {
        dispatch(
          handlePatchIsActiveNotificationState({
            updating: false,
            updated: true,
          })
        );
        queryClient.invalidateQueries(["notification_list"]);
        queryClient.invalidateQueries(["adm_notification"]);
        queryClient.invalidateQueries(["adm_notification_list"]);
      },
    }
  );

  // Delete
  const deleteNotificationMutation = useMutation(
    (id) => client.delete(`/adm_notification/${id}/`),
    {
      onMutate: () => {
        dispatch(handleDeleteNotificationState({ updating: true }));
      },
      onSuccess: () => {
        dispatch(
          handleDeleteNotificationState({ updating: false, updated: true })
        );
        queryClient.invalidateQueries(["notification_list"]);
        queryClient.invalidateQueries(["adm_notification_list"]);
      },
    }
  );

  return {
    postNotificaitonMutation,
    putNotificationMutation,
    patchIsActivedNotificationMutation,
    deleteNotificationMutation,
  };
};

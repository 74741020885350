// Header：右側Avatar
import { memo, useCallback, useState, useRef } from "react";
import { ClickAwayListener, Grow, Paper, Popper, Card } from "@mui/material";
import { HeaderAvatarProfile } from "./HeaderAvatarProfile";
import { CustomAvatar } from "../../Share/CustomAvatar";

export const HeaderAvatar = memo((props) => {
  const { data, loading } = props;
  const [avatarMenuPopper, setAvatarMenuPopper] = useState(false);
  const avatarMenuAnchorRef = useRef(null);

  const handleToggle = useCallback(() => {
    setAvatarMenuPopper(!avatarMenuPopper);
  }, [avatarMenuPopper]);

  const handleClose = useCallback(
    (event) => {
      if (
        avatarMenuAnchorRef.current &&
        avatarMenuAnchorRef.current.contains(event.target)
      ) {
        return;
      }
      setAvatarMenuPopper(false);
    },
    [avatarMenuAnchorRef, avatarMenuPopper]
  );

  return (
    <>
      <CustomAvatar
        data={data ? data : { user_avatar: null, display_name: "" }}
        onClick={handleToggle}
        anchorref={avatarMenuAnchorRef}
        sx={{
          width: 48,
          height: 48,
          cursor: "pointer",
          opacity: loading && 0.6,
          pointerEvents: loading && "none",
        }}
      />
      <Popper
        sx={{ zIndex: 1 }}
        open={avatarMenuPopper}
        anchorEl={avatarMenuAnchorRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper
              placement="bottom-end"
              sx={{ p: 1, minWidth: 278, bgcolor: "primary.main", mr: 1 }}
              elevation={8}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <Card sx={{ p: 1 }}>
                  <HeaderAvatarProfile />
                </Card>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
});

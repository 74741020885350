// お知らせID指定単体取得
import { useQuery } from "react-query";
import { client } from "../utils/authClient";

const getNotification = async (id) => {
  const { data } = await client.get(`/notification/${id}`);
  return data;
};

export const useQueryNotificationApi = (id) => {
  return useQuery({
    queryKey: ["notification", id],
    queryFn: () => id && getNotification(id),
    cacheTime: 0,
    staleTime: 0,
  });
};

// お知らせ作成・編集Dialog_開始終了日・公開状態・タイトル編集_開始終了日用DatePicker
import { memo } from "react";
import { TextField, Button, Box } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

export const CreateEditNotificationDialogDatePicker = memo((props) => {
  const { isEndDate, onClickClear } = props;
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker
          {...props}
          inputFormat="YYYY/MM/DD"
          renderInput={(params) => (
            <TextField
              {...params}
              inputProps={{
                ...params.inputProps,
                readOnly: true,
              }}
            />
          )}
        />
      </LocalizationProvider>
      {isEndDate && <Button onClick={onClickClear}>clear</Button>}
    </Box>
  );
});

// MyPage:あなたへのお知らせ
import { useCallback } from "react";
import { Box, Typography, Divider, Button } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { ContentCard } from "../Components/Share/ContentCard";
import { MyPageMessageDialog } from "../Components/MyPage/MyPageMessageDialog";
import { useQueryMessageListApi } from "../hooks/useQueryMessageListApi";
import { MyPageMessageList } from "../Components/MyPage/MyPageMessageList";
import { useMutateMessageApi } from "../hooks/useMutateMessageApi";
import { ScrollBox } from "../Components/Share/ScrollBox";

export const MyPageMessage = () => {
  const { data, isLoading } = useQueryMessageListApi();
  const { patchIsReadMessageMutation } = useMutateMessageApi();

  const unreadMessage =
    data &&
    data.results
      .filter((x) => x.text.type !== "invalid")
      .filter((x) => !x.is_read);

  // 全て既読にする
  const patchAllUnreadMessage = useCallback(() => {
    unreadMessage.map((x) => {
      patchIsReadMessageMutation.mutate(x.id);
    });
  }, [unreadMessage]);

  return (
    <>
      <MyPageMessageDialog />
      <ContentCard>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            color: "base.contrastText",
            mb: 1,
          }}
        >
          <NotificationsIcon />
          <Typography variant="h7" sx={{ ml: 1, flexGrow: 1 }}>
            あなたへのお知らせ
          </Typography>
          <Button
            disabled={
              patchIsReadMessageMutation.isLoading || !unreadMessage.length
            }
            onClick={patchAllUnreadMessage}
          >
            {patchIsReadMessageMutation.isLoading
              ? "更新中"
              : !unreadMessage.length
              ? "未読のお知らせはありません"
              : "全て既読にする"}
          </Button>
        </Box>
        <Divider />
        <ScrollBox>
          <MyPageMessageList
            messageList={!isLoading && data ? data.results : []}
            loading={isLoading}
          />
        </ScrollBox>
      </ContentCard>
    </>
  );
};

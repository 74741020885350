// 共通Component_Pagination
import { memo, useCallback } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Pagination, Box, Typography } from "@mui/material";

export const CustomPagination = memo((props) => {
  const { count, loading, pageSize } = props;
  const { index } = useParams();
  const url = useLocation();
  const navigate = useNavigate();

  const onChangePagination = useCallback(
    async (event, value) => {
      navigate(
        `${url.pathname.substring(
          0,
          url.pathname.indexOf("index")
        )}index${value}${url.search}`
      );
    },
    [url]
  );
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Pagination
        size="small"
        count={Math.ceil(count / (pageSize ? pageSize : 20))}
        onChange={onChangePagination}
        page={parseInt(index)}
        showFirstButton
        showLastButton
        disabled={loading}
      />
      <Typography variant="subtitle1" sx={{ ml: 4 }}>{`Total：${
        count ? count : ""
      }`}</Typography>
    </Box>
  );
});

// 記事閲覧_コメント欄操作_コメント新規作成
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField, Box, Button } from "@mui/material";
import { onChangeArticleComment } from "../../features/articleStateSlice";
import { useMutateArticleOptionsApi } from "../../hooks/useMutateArticleOptionsApi";

export const ArticleCommentsPostArea = memo(({ id }) => {
  const dispatch = useDispatch();
  const { articleCreateComment, articleCommentUpdating } = useSelector(
    (state) => state.articleState
  );
  const { postArticleCommentMutation } = useMutateArticleOptionsApi();

  return (
    <Box sx={{ display: "flex", pt: 2 }}>
      <Box sx={{ width: "90%", mx: 2 }}>
        <TextField
          fullWidth
          minRows={2}
          multiline
          label="この記事にコメントする"
          value={articleCreateComment}
          onChange={(event) =>
            dispatch(onChangeArticleComment(event.target.value))
          }
        />
      </Box>
      <Box>
        <Button
          variant="contained"
          onClick={() => {
            postArticleCommentMutation.mutate(id);
            dispatch(onChangeArticleComment(""));
          }}
          disabled={!articleCreateComment || articleCommentUpdating}
        >
          送信
        </Button>
      </Box>
    </Box>
  );
});

// SideMenu_メニューボタン
import { memo } from "react";
import { useNavigate } from "react-router";
import { Box, Divider, Typography } from "@mui/material";

export const SideMenuItemButton = memo(({ item, loading, isActive }) => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          color: "base.main",
          opacity: loading ? 0.2 : isActive ? 1 : 0.46,
          bgcolor: isActive && "primary.main",
          cursor: !loading && "pointer",
          ":hover": {
            opacity: 1,
            bgcolor: "sideDrawer.hover",
          },
          py: 2.6,
          pointerEvents: loading && "none",
        }}
        onClick={() => navigate(item.path)}
      >
        {item.icon}
        <Typography variant="body2">{item.label}</Typography>
      </Box>
      <Divider sx={{ bgcolor: "sideDrawer.divider" }} />
    </>
  );
});

// あなたへのお知らせ_あなたへのお知らせリスト
import { memo } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  Divider,
  Chip,
} from "@mui/material";
import { handleMessageDialog } from "../../features/messageStateSlice";
import { useMutateMessageApi } from "../../hooks/useMutateMessageApi";
import { LoadingBar } from "../Share/LoadingBar";
import { ListItemBox } from "../Share/ListItemBox";
import { messageTemplate } from "../../Containers/MyPageMessageTemplate";

export const MyPageMessageList = memo((props) => {
  const dispatch = useDispatch();
  const { messageList, loading } = props;
  const { patchIsReadMessageMutation } = useMutateMessageApi();

  const validMessageList = messageList.filter((x) => x.text.type !== "invalid");

  return (
    <>
      <LoadingBar loading={loading} />
      <List disablePadding>
        {validMessageList.length ? (
          validMessageList.map((item, index) => {
            return (
              <Box key={index}>
                <ListItem disablePadding>
                  <ListItemButton
                    onClick={() => {
                      !item.is_read &&
                        patchIsReadMessageMutation.mutate(item.id);
                      dispatch(handleMessageDialog(item.id));
                    }}
                  >
                    <ListItemText
                      primary={
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              height: 38,
                            }}
                          >
                            <Typography variant="subtitle2">
                              {item.send_at}
                            </Typography>
                            {!item.is_read && (
                              <Chip
                                label="未読"
                                size="small"
                                color="error"
                                sx={{ ml: 1 }}
                              />
                            )}
                          </Box>
                          <ListItemBox>
                            <Typography>
                              {messageTemplate(item.text).subject}
                            </Typography>
                          </ListItemBox>
                        </Box>
                      }
                    />
                  </ListItemButton>
                </ListItem>
                <Divider sx={{ mx: 2 }} />
              </Box>
            );
          })
        ) : (
          <Box textAlign="center" sx={{ display: loading && "none", mt: 2 }}>
            <Typography>お知らせはありません</Typography>
          </Box>
        )}
      </List>
    </>
  );
});

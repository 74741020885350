// 管理者用)記事閲覧_管理者Menu_公開状態変更確認Dialog
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Typography } from "@mui/material";
import { UpdateDbDialog } from "../Share/UpdateDbDialog";
import { handlePatchAdminBannedArticleState } from "../../features/articleStateSlice";
import { useMutateArticleApi } from "../../hooks/useMutateArticleApi";

export const PatchAdminBannedArticleCheckDialog = memo(({ params }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const { patchAdminBannedArticleState, previousURL } = useSelector(
    (state) => state.articleState
  );
  const { patchAdminBannedArticleMutation } = useMutateArticleApi();

  return (
    <UpdateDbDialog
      open={patchAdminBannedArticleState.dialog}
      onClickCloseDialog={() =>
        dispatch(handlePatchAdminBannedArticleState({ dialog: false }))
      }
      content={
        <Typography color="error">
          {params.is_banned
            ? "この記事を公開禁止にします"
            : "この記事の公開を許可します"}
        </Typography>
      }
      onClickUpdate={() => patchAdminBannedArticleMutation.mutate(params)}
      updating={patchAdminBannedArticleState.updating}
      updated={patchAdminBannedArticleState.updated}
      onClickCheckUpdated={() => {
        dispatch(
          handlePatchAdminBannedArticleState({ dialog: false, updated: false })
        );
        if (pathname == `/article_search/${params.id}`) {
          previousURL
            ? navigate(previousURL)
            : navigate("/admin/article/index1");
        }
      }}
    />
  );
});

// 共通Component_LoadingBar（ロード完了時に画面が動かないよう、同じ高さの空白で穴埋めしています）
import { memo } from "react";
import { Box, LinearProgress } from "@mui/material";

export const LoadingBar = memo(({ loading }) => {
  return (
    <>
      {loading ? (
        <LinearProgress sx={{ height: 2 }} />
      ) : (
        <Box sx={{ height: 2 }} />
      )}
    </>
  );
});

// 自分の記事リスト取得
import { useQuery } from "react-query";
import { client } from "../utils/authClient";

const getMyArticleList = async (page) => {
  const { data } = await client.get("/my_article/", {
    params: {
      page: page,
      page_size: 20,
    },
  });
  return data;
};

export const useQueryMyArticleListApi = (page) => {
  return useQuery({
    queryKey: ["my_article_list", page],
    queryFn: () => getMyArticleList(page),
    cacheTime: 1000 * 60 * 3,
    staleTime: 0,
  });
};

// 記事閲覧_コメント欄操作_コメントリスト_コメント削除
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useMutateArticleOptionsApi } from "../../hooks/useMutateArticleOptionsApi";
import { handleDeleteArticleCommentCheckDialog } from "../../features/articleStateSlice";

export const DeleteArticleCommentCheckDialog = memo(({ id }) => {
  const dispatch = useDispatch();
  const { deleteArticleCommentCheckDialog } = useSelector(
    (state) => state.articleState
  );
  const { deleteArticleCommentMutation } = useMutateArticleOptionsApi();

  return (
    <Dialog open={deleteArticleCommentCheckDialog}>
      <DialogContent>
        <Typography color="error" sx={{ textAlign: "center" }}>
          このコメントを削除します
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            deleteArticleCommentMutation.mutate(id);
            dispatch(handleDeleteArticleCommentCheckDialog());
          }}
        >
          OK
        </Button>
        <Button
          onClick={() => dispatch(handleDeleteArticleCommentCheckDialog())}
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
});

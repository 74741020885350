// お知らせ作成Dialog
import { memo, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider } from "@mui/material";
import { TextEditor } from "../Share/TextEditor";
import { ArticleNotificationDialogTemplate } from "../Share/ArticleNotificationDialogTemplate ";
import { CreateEditArticleNotificationDialogMainMenu } from "../Share/CreateEditArticleNotificationDialogMainMenu";
import { CreateEditNotificationDialogTitleArea } from "./CreateEditNotificationDialogTitleArea";
import {
  handleCreateNotificationPreviewTab,
  handlePostNotificationState,
  onChangeNotificationParams,
  onClickCreateNotificationDialogClose,
} from "../../features/notificationStateSlice";
import { CreateEditNotificationDialogPreviewText } from "./CreateEditNotificationDialogPreviewText";
import { NotificationDialogTitleArea } from "./NotificationDialogTitleArea";

export const CreateNotificationDialog = memo(() => {
  const dispatch = useDispatch();
  const editorCore = useRef(null);
  const {
    createNotificationDialog,
    notificationParams,
    createNotificationPreviewTab,
  } = useSelector((state) => state.notificationState);

  // タイトル文字数制限
  const capsLimit = notificationParams.title.length > 50;

  // 投稿・更新ボタン無効条件
  const disableButton = // タイトル未入力
    !notificationParams.title ||
    // タイトル文字数制限超過
    capsLimit;

  // プレビューEditor本文
  const [previewText, setPreviewText] = useState();

  // プレビュー・編集選択Tab
  const handlePreviewTab = useCallback(
    async (event, value) => {
      if (!createNotificationPreviewTab) {
        const saveCore = await editorCore.current.save();
        setPreviewText(saveCore);
        dispatch(
          onChangeNotificationParams({ text: JSON.stringify(saveCore) })
        );
      }
      dispatch(handleCreateNotificationPreviewTab(value));
    },
    [createNotificationPreviewTab, editorCore]
  );

  // 投稿押下時、editorの編集内容をグローバルステートへ反映
  const onClickPostNotificaiotnCheckDialogOpen = useCallback(async () => {
    if (!createNotificationPreviewTab) {
      const saveCore = await editorCore.current.save();
      dispatch(onChangeNotificationParams({ text: JSON.stringify(saveCore) }));
    }
    dispatch(handlePostNotificationState({ dialog: true }));
  }, [createNotificationPreviewTab, editorCore]);

  return (
    <>
      <ArticleNotificationDialogTemplate
        open={createNotificationDialog}
        onClickClose={() => dispatch(onClickCreateNotificationDialogClose())}
        titleContent={
          <>
            <CreateEditArticleNotificationDialogMainMenu
              label="お知らせ作成"
              handleFetchDialog={onClickPostNotificaiotnCheckDialogOpen}
              tab={createNotificationPreviewTab}
              handleTab={handlePreviewTab}
              disableButton={disableButton}
              isPost
            />
            <Divider sx={{ mb: 2 }} />
            {createNotificationPreviewTab ? (
              <NotificationDialogTitleArea data={notificationParams} />
            ) : (
              <CreateEditNotificationDialogTitleArea isPost />
            )}
            <Divider sx={{ mt: 1 }} />
          </>
        }
        bodyContent={
          createNotificationPreviewTab ? (
            <CreateEditNotificationDialogPreviewText
              previewText={previewText}
            />
          ) : (
            <TextEditor
              defaultValue={
                notificationParams.text && JSON.parse(notificationParams.text)
              }
              editorCore={editorCore}
            />
          )
        }
      />
    </>
  );
});

// 記事閲覧_作者・作成日・タイトル・その他情報エリア_記事に紐付くTagリスト
import { memo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Chip } from "@mui/material";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { useQueryTagApi } from "../../hooks/useQueryTag";
import { onChangeArticleSearchParams } from "../../features/articleSearchParamsSlice";

export const ArticleTagList = memo(({ tagItems }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const { data, isLoading } = useQueryTagApi();

  if (isLoading) return;

  const onClickTag = useCallback(
    (tag) => {
      const targetTag = data.filter((x) => x.name == tag);
      dispatch(
        onChangeArticleSearchParams({
          author: "",
          tags: [targetTag[0]],
          keyword: "",
          bookmarked: false,
          myArticle: false,
        })
      );
      navigate(`/article_search/results/index1?tags=${targetTag[0].slug}`);
    },
    [data]
  );

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: pathname.includes("/article_search")
          ? "100%"
          : { xs: "84vw", sm: "90vw", md: "67vw", lg: "71vw", xl: "73vw" },
        overflow: "auto",
      }}
    >
      <LocalOfferIcon
        fontSize="small"
        sx={{
          color: "base.contrastText",
          mr: 1,
        }}
      />
      {tagItems.map((item) => {
        return (
          <Chip
            key={item}
            label={item}
            sx={{
              mr: 0.2,
              pointerEvents:
                pathname.includes("/article_search/for_another_tag") && "none",
              cursor:
                !pathname.includes("/article_search/for_another_tag") &&
                "pointer",
            }}
            size="small"
            onClick={() => onClickTag(item)}
          />
        );
      })}
    </Box>
  );
});

// Article:記事閲覧
import { useRef } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { Box, Divider, Typography } from "@mui/material";
import { TextEditor } from "../Components/Share/TextEditor";
import { useQueryArticleApi } from "../hooks/useQueryArticleApi";
import { ArticleTitleArea } from "../Components/Article/ArticleTitleArea";
import { ContentCard } from "../Components/Share/ContentCard";
import { ArticleCommentsPostArea } from "../Components/Article/ArticleCommentsPostArea";
import { ArticleCommentsList } from "../Components/Article/ArticleCommentsList";
import { EditArticleCommentDialog } from "../Components/Article/EditArticleCommentDialog";
import { DeleteArticleCommentCheckDialog } from "../Components/Article/DeleteArticleCommentCheckDialog";
import { CreateReplyArticleCommentDialog } from "../Components/Article/CreateReplyArticleCommentDialog";
import { useQueryMeApi } from "../hooks/useQueryMeApi";
import { ArticleMyArticleMenu } from "../Components/Article/ArticleMyArticleMenu";
import { DeleteArticleCheckDialog } from "../Components/ArticleDialog/DeleteArticleCheckDialog";
import { ArticleAdminMenu } from "../Components/Article/ArticleAdminMenu";
import { LoadingCircular } from "../Components/Share/LoadingCircular";
import { ScrollBox } from "../Components/Share/ScrollBox";

export const Article = () => {
  const { id } = useParams();
  const article = useQueryArticleApi(id);
  const me = useQueryMeApi();
  const editorCore = useRef(null);
  const { putArticleState } = useSelector((state) => state.articleState);

  if (article.isLoading || me.isLoading) {
    return (
      <ContentCard>
        <LoadingCircular />
      </ContentCard>
    );
  }

  if (!article.data) {
    return (
      <ContentCard>
        <Box
          sx={{
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <Typography>記事が見つかりませんでした</Typography>
            <Typography>
              （この記事は削除されたか、非公開の可能性があります）
            </Typography>
          </Box>
        </Box>
      </ContentCard>
    );
  }
  return (
    <>
      <DeleteArticleCheckDialog id={id} />
      <CreateReplyArticleCommentDialog id={id} />
      <EditArticleCommentDialog id={id} />
      <DeleteArticleCommentCheckDialog id={id} />
      <ContentCard>
        <Box sx={{ pb: 1.2, px: { xs: 1, sm: 2, md: 4 } }}>
          {me.data.id == article.data.owner.id && (
            <ArticleMyArticleMenu data={article.data} />
          )}
          {me.data.is_staff && <ArticleAdminMenu data={article.data} />}
          <ArticleTitleArea data={article.data} />
        </Box>
        <Divider sx={{ mx: 2, mb: 2 }} />
        <ScrollBox>
          {!putArticleState.updated && (
            <TextEditor
              readOnly
              editorCore={editorCore}
              defaultValue={JSON.parse(article.data.text)}
            />
          )}
          <Divider sx={{ fontSize: 14, mx: 2 }}>Comments</Divider>
          <Box sx={{ px: { xs: 1, lg: 4 } }}>
            <ArticleCommentsPostArea id={id} />
            <ArticleCommentsList id={id} />
          </Box>
        </ScrollBox>
      </ContentCard>
    </>
  );
};

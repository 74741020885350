// アバター画像更新Mutate
import { useState, useCallback } from "react";
import { useMutation, useQueryClient } from "react-query";

import { client } from "../utils/authClient";

export const useMutateAvatarApi = () => {
  const [newAvatar, setNewAvatar] = useState([]);
  const [putAvatarState, setPutAvatorState] = useState({
    dialog: false,
    updating: false,
    updated: false,
  });
  const queryClient = useQueryClient();

  // onChange：選択した画像をStateで保持
  const onChangeAddImage = useCallback((event) => {
    setNewAvatar(event.target.files);
  }, []);

  // Avator画像変更Dialog開閉
  const handleEditAvatarDialog = useCallback(() => {
    !putAvatarState.dialog && setNewAvatar([]);
    setPutAvatorState({ ...putAvatarState, dialog: !putAvatarState.dialog });
  }, [putAvatarState]);

  //  アバター更新
  const putAvatarMutation = useMutation(
    async (id) => {
      const formData = await new FormData();
      await formData.append("image", newAvatar[0]);
      client.put(`/my_avatar/${id}/`, formData);
    },
    {
      onMutate: () => {
        setPutAvatorState({ ...putAvatarState, updating: true });
      },
      onSuccess: () => {
        setPutAvatorState({
          ...putAvatarState,
          updating: false,
          updated: true,
        });
      },
    }
  );

  return {
    newAvatar,
    onChangeAddImage,
    handleEditAvatarDialog,
    putAvatarState,
    putAvatarMutation,
  };
};

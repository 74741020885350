// 記事閲覧_自分の記事Menu_公開状態切替Dialog
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Typography } from "@mui/material";
import { UpdateDbDialog } from "../Share/UpdateDbDialog";
import { handlePatchIsPublishedArticleState } from "../../features/articleStateSlice";
import { useMutateArticleApi } from "../../hooks/useMutateArticleApi";

export const PatchIsPubulishedArticleCheckDialog = memo((props) => {
  const { params, onChangeArticleIsPublished } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const { patchIsPublishedArticleState, previousURL } = useSelector(
    (state) => state.articleState
  );
  const { patchIsPublishedArticleMutation } = useMutateArticleApi();

  return (
    <UpdateDbDialog
      open={patchIsPublishedArticleState.dialog}
      onClickCloseDialog={onChangeArticleIsPublished}
      content={
        <Typography color="error">この記事の公開状態を変更します</Typography>
      }
      onClickUpdate={() => patchIsPublishedArticleMutation.mutate(params)}
      updating={patchIsPublishedArticleState.updating}
      updated={patchIsPublishedArticleState.updated}
      onClickCheckUpdated={() => {
        dispatch(
          handlePatchIsPublishedArticleState({ dialog: false, updated: false })
        );
        if (pathname == `/article_search/${params.id}`) {
          previousURL
            ? navigate(previousURL)
            : navigate("/my_page/my_article/index1");
        }
      }}
    />
  );
});

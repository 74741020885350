// 記事閲覧_コメント欄操作_コメントリスト_コメント編集
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  TextField,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  handleEditArticleCommentDialog,
  onChangeEditArticleComment,
} from "../../features/articleStateSlice";
import { useMutateArticleOptionsApi } from "../../hooks/useMutateArticleOptionsApi";

export const EditArticleCommentDialog = memo(({ id }) => {
  const dispatch = useDispatch();
  const { articleEditDeleteCommentParams, editArticleCommentDialog } =
    useSelector((state) => state.articleState);
  const { patchArticleCommentMutation } = useMutateArticleOptionsApi();

  return (
    <Dialog open={editArticleCommentDialog} fullWidth maxWidth="sm">
      <DialogContent sx={{ mt: 1 }}>
        <Box sx={{ width: "100%" }}>
          <TextField
            fullWidth
            minRows={3}
            multiline
            label="コメント編集"
            value={articleEditDeleteCommentParams.text}
            onChange={(event) =>
              dispatch(onChangeEditArticleComment(event.target.value))
            }
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ mb: 1 }}>
        <Button
          variant="contained"
          onClick={() => {
            patchArticleCommentMutation.mutate(id);
            dispatch(handleEditArticleCommentDialog());
          }}
          disabled={!Boolean(articleEditDeleteCommentParams.text)}
        >
          更新
        </Button>
        <Button onClick={() => dispatch(handleEditArticleCommentDialog())}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
});

// 記事コメントリスト取得
import { useQuery } from "react-query";
import { client } from "../utils/authClient";

const getArticleComent = async (id) => {
  const { data } = await client.get(`article/${id}/comment/`);
  return data;
};

export const useQueryArticleCommentApi = (id) => {
  return useQuery({
    queryKey: ["article_comment"],
    queryFn: () => getArticleComent(id),
    refetchOnMount: true,
    cacheTime: 0,
    staleTime: 0,
  });
};

import React from "react";
import { createRoot } from "react-dom/client";
import { PublicClientApplication } from "@azure/msal-browser";
import CssBaseline from "@mui/material/CssBaseline";

import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./Styles/theme";
import { AuthRoutes } from "./AuthRoutes";
import { msalConfig } from "./utils/authConfig";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

export const msalInstance = new PublicClientApplication(msalConfig);

root.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <AuthRoutes instance={msalInstance} />
  </ThemeProvider>
  // </React.StrictMode>
);

// 記事閲覧_コメント欄操作_コメントリスト_リストアイテム
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  ListItem,
  ListItemText,
  ListItemAvatar,
  Typography,
  Box,
  IconButton,
  Chip,
  Tooltip,
  Zoom,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useQueryMeApi } from "../../hooks/useQueryMeApi";
import {
  handleDeleteArticleCommentCheckDialog,
  handleEditArticleCommentDialog,
} from "../../features/articleStateSlice";
import { CustomAvatar } from "../Share/CustomAvatar";
import { handleOwnerDialog } from "../../features/otherStateSlice";

export const ArticleCommentsListItem = memo(({ item }) => {
  const dispatch = useDispatch();
  const pathname = useLocation().pathname;
  const { data } = useQueryMeApi();
  const { articleCommentUpdating } = useSelector((state) => state.articleState);

  return (
    <ListItem alignItems="flex-start" sx={{ py: 0 }}>
      <ListItemAvatar
        sx={{
          m: "auto",
          cursor:
            !pathname.includes("/article_search/for_another_tag") && "pointer",
          pointerEvents:
            pathname.includes("/article_search/for_another_tag") && "none",
        }}
        onClick={() => dispatch(handleOwnerDialog(item.owner))}
      >
        <CustomAvatar data={item.owner} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="subtitle2" fontSize={12}>
                {item.created_at}
              </Typography>
              <Typography variant="body2">
                {`@${item.owner.display_name}`}
              </Typography>
            </Box>
            {item.owner.id == data.id ? (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Chip
                  label="自分のコメント"
                  color="secondary"
                  variant="outlined"
                  size="small"
                  sx={{ mr: 1 }}
                />
                <Tooltip title="編集" TransitionComponent={Zoom}>
                  <IconButton
                    onClick={() =>
                      dispatch(
                        handleEditArticleCommentDialog({
                          id: item.id,
                          text: item.text,
                        })
                      )
                    }
                    disabled={articleCommentUpdating}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="削除" TransitionComponent={Zoom}>
                  <IconButton
                    color="error"
                    onClick={() =>
                      dispatch(
                        handleDeleteArticleCommentCheckDialog({ id: item.id })
                      )
                    }
                    disabled={articleCommentUpdating}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            ) : (
              data.is_staff && (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Chip label="管理者" variant="outlined" size="small" />
                  <IconButton
                    color="error"
                    onClick={() =>
                      dispatch(
                        handleDeleteArticleCommentCheckDialog({ id: item.id })
                      )
                    }
                    disabled={articleCommentUpdating}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </Box>
              )
            )}
          </Box>
        }
        secondary={
          <>
            <Typography
              sx={{ display: "inline" }}
              component="span"
              variant="body2"
              color="text.primary"
            >
              {item.text}
            </Typography>
          </>
        }
      />
    </ListItem>
  );
});

// 記事新規作成ボタン/記事閲覧Dialog複写登録共通_新規投稿確認Dialog
import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { UpdateDbDialog } from "../Share/UpdateDbDialog";
import {
  handleCreateArticlePreviewTab,
  handlePostArticleState,
  onClickClearArticleParams,
  onClickCreateArticleDialogClose,
  handleMyArticleDialog,
} from "../../features/articleStateSlice";
import { useMutateArticleApi } from "../../hooks/useMutateArticleApi";

export const PostArticleCheckDialog = memo(() => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { postArticleState, myArticleDialog } = useSelector(
    (state) => state.articleState
  );
  const { postArticleMutation } = useMutateArticleApi();

  return (
    <>
      <UpdateDbDialog
        open={postArticleState.dialog}
        onClickCloseDialog={() =>
          dispatch(handlePostArticleState({ dialog: false }))
        }
        content={<Typography color="error">この内容で投稿します</Typography>}
        onClickUpdate={() => postArticleMutation.mutate()}
        updating={postArticleState.updating}
        updated={postArticleState.updated}
        onClickCheckUpdated={() => {
          dispatch(onClickClearArticleParams());
          dispatch(handlePostArticleState({ dialog: false, updated: false }));
          dispatch(handleCreateArticlePreviewTab(0));
          dispatch(onClickCreateArticleDialogClose());
          myArticleDialog.dialog && dispatch(handleMyArticleDialog());
          navigate("/my_page/my_article/index1");
        }}
      />
    </>
  );
});

// 自分の記事ID指定単体取得
import { useQuery } from "react-query";
import { client } from "../utils/authClient";

const getMyArticle = async (id) => {
  const { data } = await client.get(`/my_article/${id}`);
  return data;
};

export const useQueryMyArticleApi = (id) => {
  return useQuery({
    queryKey: ["my_article", id],
    queryFn: () => id && getMyArticle(id),
    refetchOnMount: true,
    cacheTime: 0,
    staleTime: 0,
  });
};

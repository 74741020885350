// 共通Component_リスト画面縮小時の文字崩れ防止の為、Typographyを以下で囲む
import { memo } from "react";
import { Box } from "@mui/material";

export const ListItemBox = memo(({ children }) => {
  return (
    <Box
      sx={{
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
      }}
    >
      {children}
    </Box>
  );
});

//　SideMunu
import { memo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Divider, Drawer, IconButton } from "@mui/material";
import { SideMenuSubMenuArea } from "./SideMenuComponents/SideMenuSubMenuArea";
import { SideMenuMainMenuArea } from "./SideMenuComponents/SideMenuMainMenuArea";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { handleSideDrawer } from "../../features/otherStateSlice";
import { useQueryMeApi } from "../../hooks/useQueryMeApi";
import { useQueryTagApi } from "../../hooks/useQueryTag";
import { useQueryMessageListApi } from "../../hooks/useQueryMessageListApi";

export const SideMenu = memo(() => {
  const dispatch = useDispatch();
  const { sideDrawer } = useSelector((state) => state.otherState);

  const me = useQueryMeApi();
  const tag = useQueryTagApi();
  const message = useQueryMessageListApi();

  const loading = me.isLoading || tag.isLoading || message.isLoading;

  return (
    <>
      {/* 大画面時 */}
      <Box
        sx={{
          bgcolor: "sideDrawer.main",
          height: "100vh",
          minWidth: 86,
          overflow: "hidden",
          pt: 9,
          display: { xs: "none", sm: "none", md: "block" },
        }}
      >
        <SideMenuMainMenuArea data={me.data} loading={loading} />
      </Box>
      <Box sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
        <SideMenuSubMenuArea loading={loading} />
      </Box>
      {/* 画面縮小時Drawer */}
      <Drawer
        sx={{ zIndex: 1300, display: { md: "none" } }}
        onClose={() => dispatch(handleSideDrawer())}
        open={sideDrawer}
      >
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              minWidth: 92,
              bgcolor: "sideDrawer.main",
              height: "100vh",
              overflow: "hidden",
            }}
          >
            <IconButton
              onClick={() => dispatch(handleSideDrawer())}
              color="base"
              size="large"
              sx={{ ml: 1, mt: 1, mb: 0.7 }}
            >
              <NavigateBeforeIcon fontSize="large" />
            </IconButton>
            <Divider sx={{ bgcolor: "sideDrawer.divider" }} />
            <SideMenuMainMenuArea data={me.data} loading={loading} />
          </Box>
          <SideMenuSubMenuArea loading={loading} />
        </Box>
      </Drawer>
    </>
  );
});
